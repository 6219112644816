import { supabase } from '../supabase';
import { useAuthUser } from '../../state/authUser';
import { Enums } from '../supabase/schema';
import { sendEmail } from '../mailgun';

export const createShowcaseSubmission = async (projectUrl: string, showcaseId: number, videoUrl?: string) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  await supabase.from('submissions').insert({
    user_id: userId,
    project_url: projectUrl,
    showcase_id: showcaseId,
    video_url: videoUrl,
  });

  await Promise.all([
    "jack@scopelabs.com",
    "cory@scopelabs.com",
    "strachan@scopelabs.com",
    "jason@scopelabs.com",
    "nick@scopelabs.com"
  ].map(async email => await sendEmail(
    email,
    "New 10Fold Submission",
    `<div><p>A new showcase submission was just posted on 10Fold!</p><ul><li>showcase id: ${showcaseId}</li><li>user id: ${userId}</li><li>project url: ${projectUrl}</li><li>video url: ${videoUrl}</li></ul></div>`)))
};

export const createProjectSubmission = async (
  title: string,
  projectUrl: string,
  description: string,
  projectType: Enums<'stack_area'>,
  skillIds: number[],
  videoUrl?: string,
  coverImage?: File,
) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  const { data, error } = await supabase
    .from('submissions')
    .insert({
      title,
      user_id: userId,
      project_url: projectUrl,
      description,
      type: projectType,
      video_url: videoUrl,
    })
    .select('id')
    .single();
  if (error) throw error;
  if (data.id != null) {
    const skillsPromise =
      skillIds.length > 0
        ? supabase
            .from('submission_skills')
            .insert(skillIds.map((skillId) => ({ skill_id: skillId, submission_id: data.id })))
        : null;
    const coverPromise =
      coverImage != null ? supabase.storage.from('project_cover_images').upload(data.id.toString(), coverImage) : null;
    await Promise.all([skillsPromise, coverPromise]);
  }
};
