import React from 'react';

const AuthBackdrop = () => {
  return (
    <>
      <div className='absolute z-[0] top-12 floating select-none '>
        <img
          alt={'backdrop'}
          className='w-24 -translate-x-[400px]'
          src={require('../../assets/img/morphis/auth/morphis-electric-light-bulb-glassmorphism.png')}
        />
      </div>
      <div className='rotate-12 rotatingFloating absolute z-[0] top-12'>
        <img
          alt={'backdrop'}
          className='w-16 translate-x-[360px] '
          src={require('../../assets/img/morphis/auth/morphis-big-gear.png')}
        />
      </div>
      <div className='-scale-x-1 absolute z-[0] bottom-20 floating'>
        <img
          alt={'backdrop'}
          className='w-16 rotate-12 translate-x-[460px] '
          src={require('../../assets/img/morphis/auth/morphis-password-with-glass-effect-1.png')}
        />
      </div>
      <div className='rotate-12 -scale-x-1 absolute z-[0] bottom-24 rotatingFloating'>
        <img
          alt={'backdrop'}
          className='w-16  -translate-x-[260px]'
          src={require('../../assets/img/morphis/auth/morphis-speech-bubble-with-percentage-symbol.png')}
        />
      </div>
      <div className=' -scale-x-1 absolute z-[0] bottom-40 floating'>
        <img
          alt={'backdrop'}
          className='w-16 translate-x-[220px]'
          src={require('../../assets/img/morphis/auth/morphis-blue-arrow-threed.png')}
        />
      </div>
      <div className=' -scale-x-1 absolute z-[0] bottom-80 rotatingFloating'>
        <img
          alt={'backdrop'}
          className='w-16 rotate-[10deg] -translate-x-[480px]'
          src={require('../../assets/img/morphis/auth/morphis-green-lightning-bolt.png')}
        />
      </div>
      <div className='-scale-x-1 absolute z-[0] top-96 floating'>
        <img
          alt={'backdrop'}
          className='w-16 rotate-[-20deg] translate-x-[450px]'
          src={require('../../assets/img/morphis/auth/morphis-puzzle-element.png')}
        />
      </div>
    </>
  );
};

export default AuthBackdrop;
