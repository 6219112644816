import React from 'react';
import {cardClass, textLight} from '../../consts/styling';
import {Button} from '../atoms/Button';
import SvgArrowUpRight from '../icons/SvgArrowUpRight';
import SvgDataObject from '../icons/SvgDataObject';
import {IMAGES} from '../../assets/img';
import {UserSubmission} from '../../client/supabase/types';
import {cleanUrl} from '../../utils/stringValidation';
import {supabase} from '../../client/supabase';

interface PropsIface {
    submission: UserSubmission;
}

const ProjectCard = ({submission}: PropsIface) => {
    const [imageError, setImageError] = React.useState<boolean>(false);

    const title = React.useMemo(() => {
        return submission.title ?? submission.showcases?.title ?? 'Project';
    }, [submission.title, submission.showcases?.title]);

    const projectType = React.useMemo(() => {
        return submission.type ?? submission.showcases?.type ?? 'Generic project';
    }, [submission.type, submission.showcases?.type]);

    const description = React.useMemo(() => {
        return submission.description ?? submission.showcases?.problem_description ?? 'A project';
    }, [submission.description, submission.showcases?.problem_description]);

    const onOpenProject = React.useCallback(() => {
        const cleaned = cleanUrl(submission.project_url);
        window.open(cleaned, '_blank');
    }, [submission.project_url]);

    const imageUrl = React.useMemo(() => {
        if (imageError) {
            return submission.showcases?.cover_url ?? IMAGES.Cover;
        }
        return supabase.storage.from('project_cover_images').getPublicUrl(submission.id.toString()).data.publicUrl;
    }, [imageError, submission.id, submission.showcases?.cover_url ?? IMAGES.Cover]);

    const onImageError = React.useCallback(() => {
        setImageError(true);
    }, []);

    return (
        <div className={`${cardClass} group cursor-pointer hover:bg-slate-50 gap-3 p-3`}>
            <img
                alt={'cover'}
                src={imageUrl}
                className='w-full h-28 rounded-xl bg-slate-50 object-cover'
                onError={onImageError}
            />
            <div className='flex flex-col mr-auto'>
                <p className={`text-xs md:text-sm ${textLight}`}>{projectType} Showcase</p>
                <p className='text-lg font-semibold'>{title}</p>
                <p className='text-slate-500 flex-grow line-clamp-3'>{description}</p>
            </div>
            <div className='flex flex-row items-center justify-between gap-3'>
                <div className={'flex flex-row items-center gap-2'}>
                    {!!submission.showcases?.skills?.length && (
                        <>
                            <SvgDataObject className={'w-4 md:w-5 w-4 md:h-5'}/>
                            <p className='text-sm'>
                                {submission.showcases.skills.length} framework{submission.showcases.skills.length === 1 ? '' : 's'}
                            </p>
                        </>
                    )}
                </div>
                <Button
                    onClick={onOpenProject}
                    variant={'ghost'}
                    size={'sm'}
                    Icon={SvgArrowUpRight}
                    className={`md:opacity-0 group-hover:opacity-100`}
                >
                    View on Github
                </Button>
            </div>
        </div>
    );
};

export default ProjectCard;
