import React from 'react';
import { Button } from '../atoms/Button';
import ModalComponent from '../atoms/ModalComponent';

interface SuccessModalProps {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  description: string;
  image?: string; // Optional image prop
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, toggle, title, description, image }) => {
  return (
    <ModalComponent isOpen={isOpen} toggle={toggle}>
      <div className='mx-auto p-12 flex flex-col gap-5 text-center'>
        <div className='flex flex-col gap-3 items-center'>
          {image && <img src={image} alt={'Success Graphic'} className='w-1/3 mx-auto' />}
          <p className='text-xl font-bold'>{title}</p>
          <p className='text-lg'>{description}</p>
        </div>
        <div className='flex flex-row justify-center gap-3'>
          <Button onClick={toggle}>Finish</Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default SuccessModal;
