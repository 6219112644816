import React, { useCallback, useState } from 'react';
import TextInputWrapper from '../atoms/TextInputWrapper';
import { Button } from '../atoms/Button';
import SvgGithub from '../icons/SvgGithub';
import SvgVideo from '../icons/SvgVideo';
import ModalComponent from '../atoms/ModalComponent';
import { createShowcaseSubmission } from '../../client/apiClient/submissions';

// type Type = {
//   fullStack: boolean;
//   backEnd: boolean;
//   frontEnd: boolean;
// };

interface PropsIface {
  isOpen: boolean;
  toggle: () => void;
  showcaseId: number;
}

const AddSubmissionModal = ({ isOpen, toggle, showcaseId }: PropsIface) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [githubLink, setGithubLink] = useState<string>('');
  const [loomLink, setLoomLink] = useState<string>('');
  // TODO (comment back in when past MVP)
  // const [description, setDescription] = useState<string>('');
  // const [skillType, setSkillType] = useState<Type>({
  //   fullStack: false,
  //   backEnd: false,
  //   frontEnd: false
  // });
  // const [tags, setTags] = useState<string[]>([]);
  // const [coverImage, setCoverImage] = useState<string | ArrayBuffer | null>(null);

  // const handleImageUpload = useCallback((image: string | ArrayBuffer | null) => {
  //   setCoverImage(image);
  // }, []);

  const handleGithubLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setErrorMessage(undefined);
    setGithubLink(event.target.value);
  }, []);

  // const handleDescriptionChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
  //   (event) => {
  //     setDescription(event.target.value);
  //   },
  //   []
  // );

  const handleLoomLinkChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setLoomLink(event.target.value);
  }, []);

  // const handleCheckboxChange = useCallback((name: keyof Type, checked: boolean) => {
  //   setSkillType(prevState => ({
  //     ...prevState,
  //     [name]: checked
  //   }));
  // }, []);

  // const typeCheckboxes = React.useMemo(() => {
  //   return [
  //     { name: 'fullStack', label: 'Full Stack', checked: skillType.fullStack },
  //     { name: 'backEnd', label: 'Back End', checked: skillType.backEnd },
  //     { name: 'frontEnd', label: 'Front End', checked: skillType.frontEnd }
  //   ];
  // }, [skillType]);

  const resetAndClose = React.useCallback(() => {
    setErrorMessage(undefined);
    setLoading(false);
    setGithubLink('');
    setLoomLink('');
    toggle();
  }, [toggle]);

  const onSubmit = React.useCallback(async () => {
    setErrorMessage(undefined);
    if (!githubLink.includes('github.com')) {
      setErrorMessage('Please enter a valid project URL submission');
      return;
    }
    setLoading(true);
    if (loomLink.length === 0) {
      await createShowcaseSubmission(githubLink, showcaseId);
    } else {
      await createShowcaseSubmission(githubLink, showcaseId, loomLink);
    }
    window.gtag("event", "showcase_submitted", {
      showcase_id: showcaseId,
    })
    setLoading(false);
    resetAndClose();
    // TODO (navigate or refresh page)
  }, [githubLink, loomLink, showcaseId, resetAndClose]);

  return (
    <ModalComponent
      isOpen={isOpen}
      header={'Add your showcase submission'}
      description={"Let's get your work linked"}
      headerToggle
      toggle={resetAndClose}
      size='lg'
    >
      <div className='flex flex-col gap-5 max-h-[60vh] overflow-y-scroll'>
        {/*{coverImage &&*/}
        {/*  <img src={coverImage as string} alt='Cover'*/}
        {/*       className='w-full rounded-xl max-h-[120px] object-contain bg-slate-100' />*/}
        {/*}*/}
        {/*<DropzoneUploader*/}
        {/*    label={'Cover photo'}*/}
        {/*    hasCoverImage={!!coverImage}*/}
        {/*    onImageUpload={handleImageUpload}*/}
        {/*/>*/}
        <TextInputWrapper
          label={'Github Link'}
          value={githubLink}
          placeholder={'Link your project'}
          onChange={handleGithubLinkChange}
          required
          Icon={SvgGithub}
          helperTip={errorMessage}
        />
        {/*<TextAreaWrapper*/}
        {/*  label={'Description'}*/}
        {/*  value={description}*/}
        {/*  required*/}
        {/*  placeholder={'A brief description of your submission...'}*/}
        {/*  onChange={handleDescriptionChange}*/}
        {/*/>*/}
        <TextInputWrapper
          label={'Loom video (optional)'}
          value={loomLink}
          placeholder={'Link a walkthrough'}
          onChange={handleLoomLinkChange}
          helperTip={'A video walking through your project goes a long way!'}
          Icon={SvgVideo}
        />
        {/*<CheckboxGroup*/}
        {/*    label='Type'*/}
        {/*    required*/}
        {/*    items={typeCheckboxes}*/}
        {/*    onChange={(name, checked) => handleCheckboxChange(name as keyof Type, checked)}*/}
        {/*    direction={'row'}*/}
        {/*/>*/}
        {/*<TagsInput tags={tags} setTags={setTags} label={'Add tags'}/>*/}
      </div>
      <div className='flex flex-row justify-end gap-3 mt-8'>
        <Button variant={'secondary'} onClick={resetAndClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onSubmit} loading={loading}>
          Submit
        </Button>
      </div>
    </ModalComponent>
  );
};

export default AddSubmissionModal;
