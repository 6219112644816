import React from 'react';
import TextInputWrapper from '../atoms/TextInputWrapper';
import { Button } from '../atoms/Button';
import SvgLogoMark from '../../assets/brand/SvgLogoMark';
import { validateEmail, validatePassword } from '../../utils/stringValidation';
import { supabase } from '../../client/supabase';

type ErrorState = {
  email: string;
  password: string;
  confirmPassword: string;
};

const SignupView = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [errors, setErrors] = React.useState<ErrorState>({ email: '', password: '', confirmPassword: '' });

  const handleEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handlePasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  const handleConfirmPasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  }, []);

  const handleSubmit = React.useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      const isEmailValid = validateEmail(email);
      const isPasswordValid = validatePassword(password);
      const isConfirmPasswordValid = validatePassword(confirmPassword);

      if (password !== confirmPassword) {
        setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: 'Passwords do not match.' }));
        return;
      }
      if (isEmailValid && isPasswordValid && isConfirmPasswordValid) {
        const { error } = await supabase.auth.signUp({
          email,
          password,
        });
        if (error != null) {
          setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: 'Error' }));
          console.error(error.message);
        } else {
          window.gtag("event", "sign_up", {
            email: email
          })
        }
      }
    },
    [email, password, confirmPassword],
  );

  return (
    <form
        className='w-[95vw] md:w-[400px] flex flex-col gap-5 bg-white p-5 md:p-10 shadow-2xl rounded-2xl'
      onSubmit={handleSubmit}
      noValidate
    >
      <div className='flex flex-col items-center gap-3'>
        <h1 className='text-2xl font-semibold' tabIndex={-1}>
          Sign up
        </h1>
        <p className='text-slate-500'>Create your account.</p>
      </div>
      <TextInputWrapper
        id='email'
        label={'Email'}
        value={email}
        placeholder={'Enter your email'}
        autoFocus={true}
        type={'email'}
        autoComplete={'email'}
        onChange={handleEmailChange}
        aria-describedby='email-error'
      />
      {errors.email && (
        <p id='email-error' className='text-red-500 text-sm'>
          {errors.email}
        </p>
      )}
      <TextInputWrapper
        id='password'
        label={'Password'}
        value={password}
        placeholder={'Enter your password'}
        type={'password'}
        onChange={handlePasswordChange}
        aria-describedby='password-error'
      />
      {errors.password && (
        <p id='password-error' className='text-red-500 text-sm'>
          {errors.password}
        </p>
      )}
      <TextInputWrapper
        id='confirmPassword'
        label={'Confirm Password'}
        value={confirmPassword}
        placeholder={'Confirm your password'}
        type={'password'}
        onChange={handleConfirmPasswordChange}
        aria-describedby='confirm-password-error'
      />
      {errors.confirmPassword && (
        <p id='confirm-password-error' className='text-red-500 text-sm'>
          {errors.confirmPassword}
        </p>
      )}
      <Button type={'submit'}>Sign Up</Button>
    </form>
  );
};

export default SignupView;
