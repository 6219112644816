import React from 'react';
import { Button } from '../atoms/Button';
import { supabase } from '../../client/supabase';
import SvgLogout from '../icons/SvgLogout';

const LogOutButton = () => {
  const onLogOut = React.useCallback(async () => {
    await supabase.auth.signOut();
    window.gtag("event", "sign_out")
  }, []);

  return <Button Icon={SvgLogout} variant='secondary' onClick={onLogOut} size={'icon'} />;
};

export default LogOutButton;
