import React, { useCallback, useState } from 'react';
import TextInputWrapper from '../atoms/TextInputWrapper';
import { Button } from '../atoms/Button';
import SvgGithub from '../icons/SvgGithub';
import SvgVideo from '../icons/SvgVideo';
import ModalComponent from '../atoms/ModalComponent';
import DropzoneUploader from '../atoms/DropzoneUploader';
import TextAreaWrapper from '../atoms/TextAreaWrapper';
import CheckboxGroup from '../atoms/CheckboxGroup';
import TagsInput from '../atoms/TagsInput';
import { fw_lang_tags, top_fw_lang_tags } from '../../consts/tags';
import { Skill } from '../../client/supabase/types';
import { getAllSkills } from '../../client/apiClient/skills';
import { Enums } from '../../client/supabase/schema';
import { createProjectSubmission } from '../../client/apiClient/submissions';

type Type = {
  fullStack: boolean;
  backEnd: boolean;
  frontEnd: boolean;
};

interface PropsIface {
  isOpen: boolean;
  toggle: () => void;
}

const AddProjectModal = ({ isOpen, toggle }: PropsIface) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [title, setTitle] = useState<string>('');
  const [githubLink, setGithubLink] = useState<string>('');
  const [loomLink, setLoomLink] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [stackArea, setStackArea] = React.useState<Enums<'stack_area'> | undefined>();
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [allSkills, setAllSkills] = useState<Skill[]>([]);
  const [popularSkills, setPopularSkills] = useState<Skill[]>([]);
  const [coverImage, setCoverImage] = useState<File | undefined>();

  React.useEffect(() => {
    getAllSkills().then(({ allSkills, popularSkills }) => {
      setAllSkills(allSkills);
      setPopularSkills(popularSkills);
    });
  }, []);

  const coverImageUrl = React.useMemo(() => {
    if (coverImage != null) {
      return URL.createObjectURL(coverImage);
    }
  }, [coverImage]);

  const labelFetcher = React.useCallback((skill: Skill) => {
    return skill.skill_type;
  }, []);

  const handleImageUpload = useCallback((image: File) => {
    setCoverImage(image);
  }, []);

  const handleTitleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setTitle(event.target.value);
  }, []);

  const handleGithubLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setErrorMessage(undefined);
    setGithubLink(event.target.value);
  }, []);

  const handleDescriptionChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
    setDescription(event.target.value);
  }, []);

  const handleLoomLinkChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setLoomLink(event.target.value);
  }, []);

  const handleCheckboxChange = useCallback((name: Enums<'stack_area'>, checked: boolean) => {
    if (checked) {
      setStackArea(name);
    } else {
      setStackArea(undefined);
    }
  }, []);

  const typeCheckboxes = React.useMemo<
    {
      name: Enums<'stack_area'>;
      label: Enums<'stack_area'>;
      checked: boolean;
    }[]
  >(() => {
    return [
      { name: 'Full Stack', label: 'Full Stack', checked: stackArea == 'Full Stack' },
      { name: 'Backend', label: 'Backend', checked: stackArea == 'Backend' },
      { name: 'Frontend', label: 'Frontend', checked: stackArea == 'Frontend' },
    ];
  }, [stackArea]);

  const resetAndClose = React.useCallback(() => {
    setTitle('');
    setDescription('');
    setStackArea(undefined);
    setErrorMessage(undefined);
    setLoading(false);
    setGithubLink('');
    setLoomLink('');
    setCoverImage(undefined);
    toggle();
  }, [toggle]);

  const onSubmit = React.useCallback(async () => {
    setErrorMessage(undefined);
    if (!githubLink.includes('github.com')) {
      setErrorMessage('Please enter a valid project URL submission');
      return;
    }
    if (stackArea == null) {
      setErrorMessage('Please select a project type');
      return;
    }
    setLoading(true);
    await createProjectSubmission(
      title,
      githubLink,
      description,
      stackArea,
      selectedSkills.map((skill) => skill.id),
      loomLink,
      coverImage,
    );
    window.gtag("event", "new_project_added", {
      title,
    })
    setLoading(false);
    resetAndClose();
    window.location.reload();
  }, [title, githubLink, description, loomLink, stackArea, selectedSkills, coverImage, resetAndClose]);

  const disabled = title.length == 0 || githubLink.length == 0 || description.length == 0 || stackArea == undefined;

  return (
    <ModalComponent
      isOpen={isOpen}
      header={'Add a project to your profile'}
      description={"Let's get your work linked"}
      headerToggle
      toggle={resetAndClose}
      size='lg'
    >
      <div className='flex flex-col gap-5 max-h-[60vh] overflow-y-scroll'>
        {coverImage && (
          <img
            src={coverImageUrl}
            alt='Cover'
            className='w-full rounded-xl max-h-[120px] object-contain bg-slate-100'
          />
        )}
        <DropzoneUploader label={'Cover photo'} hasCoverImage={!!coverImage} onImageUpload={handleImageUpload} />
        <TextInputWrapper
          label={'Title'}
          value={title}
          placeholder='Choose a title'
          onChange={handleTitleChange}
          required
        />
        <TextInputWrapper
          label={'Github Link'}
          value={githubLink}
          placeholder={'Link your project'}
          onChange={handleGithubLinkChange}
          required
          Icon={SvgGithub}
          helperTip={errorMessage}
        />
        <TextAreaWrapper
          label={'Description'}
          value={description}
          required
          placeholder={'A brief description of your submission...'}
          onChange={handleDescriptionChange}
        />
        <TextInputWrapper
          label={'Loom video (optional)'}
          value={loomLink}
          placeholder={'Link a walkthrough'}
          onChange={handleLoomLinkChange}
          helperTip={'A quick video walking through your project goes a long way!'}
          Icon={SvgVideo}
        />
        <CheckboxGroup<Enums<'stack_area'>>
          label='Type'
          required
          items={typeCheckboxes}
          onChange={handleCheckboxChange}
          direction={'row'}
        />
        <TagsInput<Skill>
          tags={selectedSkills}
          setTags={setSelectedSkills}
          placeholder={'None added'}
          options={allSkills}
          topOptions={popularSkills}
          label={'Frameworks and languages'}
          labelFetcher={labelFetcher}
        />
      </div>
      <div className='flex flex-row justify-end gap-3'>
        <Button variant={'secondary'} onClick={resetAndClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onSubmit} loading={loading} disabled={disabled}>
          Submit
        </Button>
      </div>
    </ModalComponent>
  );
};

export default AddProjectModal;
