import React, { useState, useCallback } from 'react';
import ModalComponent from '../atoms/ModalComponent';
import Spinner from '../atoms/Spinner';
import { wait } from '@testing-library/user-event/dist/utils';
import { Button } from '../atoms/Button';
import TextInputWrapper from '../atoms/TextInputWrapper';
import { supabase } from '../../client/supabase';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');

  const emailChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const sendPasswordReset = useCallback(async () => {
    setProcessing(true);
    setError('');
    try {
      await supabase.auth.resetPasswordForEmail(email, {
        // TODO (jstrachan): Update to clean URL when live
        redirectTo: 'https://10fold-frontend.vercel.app/reset_password',
      });
      setProcessing(false);
      setEmailSent(true);
    } catch (error: any) {
      // Ideally, specify a more accurate type than `any` for error.
      setProcessing(false);
      setError(error.message);
    }
  }, [email]);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setEmail('');
    setModalOpen(false);
    setProcessing(false);
    setEmailSent(false);
    setError('');
  }, []);

  return (
    <>
      <div className='text-right mt-2' onClick={openModal}>
        <p className='text-sm text-red-400 hover:text-red-500 cursor-pointer'>Forgot password?</p>
      </div>
      <ModalComponent
        isOpen={modalOpen}
        backgroundColor={'white'}
        header={emailSent ? 'All set' : 'Forgot your password?'}
        headerToggle
        size={'md'}
        toggle={closeModal}
        FooterComponent={
          !emailSent ? (
            <div className='flex flex-row flex-1 gap-3'>
              <Button className={'flex-1'} variant={'secondary'} onClick={closeModal}>
                Cancel
              </Button>
              <Button className={'flex-1'} loading={processing} onClick={sendPasswordReset}>
                Send Reset Email
              </Button>
            </div>
          ) : emailSent ? (
            <Button variant={'secondary'} onClick={closeModal}>
              Close
            </Button>
          ) : null
        }
      >
        {!emailSent ? (
          <div className='mb-3'>
            <p className='text-slate-500 mb-5'>
              Enter the email address associated with your account to receive a reset email.
            </p>
            <TextInputWrapper
              label={'Email'}
              value={email}
              onChange={emailChanged}
              placeholder={'Enter email'}
              className='input-light px-3 body2 base-black-50 w-full'
            />
            {error && <p className='text-xs text-red-400 mt-1'>{error}</p>}
          </div>
        ) : (
          <>
            <p className='text-slate-500 mb-5'>
              You will receive an email shortly with instructions on how to reset your password if you have an account
              with 10Fold.
            </p>
          </>
        )}
      </ModalComponent>
    </>
  );
};

export default ForgotPassword;
