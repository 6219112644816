import React from 'react';
import RegisterForFirstShowcaseCard from '../components/cards/RegisterForFirstShowcaseCard';
import ProfileCard from '../components/cards/ProfileCard';
import SectionTabs from '../components/atoms/SectionTabs';
import ProjectCard from '../components/cards/ProjectCard';
import { Button } from '../components/atoms/Button';
import SvgFolderPlus from '../components/icons/SvgFolderPlus';
import { getUser } from '../client/apiClient/users';
import AboutMeCard from '../components/cards/AboutMeCard';
import EditProfileModal from '../components/modals/EditProfileModal';
import { Showcase, User } from '../client/supabase/types';
import AddProjectModal from '../components/modals/AddProjectModal';
import { Link } from 'react-router-dom';
import ShowcaseCard from '../components/cards/ShowcaseCard';
import LoadingPage from './LoadingPage';

type Tabs = 'Showcases' | 'Portfolio' | 'Experience';

function ProfilePage() {
  const [activeTab, setActiveTab] = React.useState<Tabs>('Showcases');
  const [user, setUser] = React.useState<User | undefined>();
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = React.useState(false);
  const [addProjectModalOpen, setAddProjectModalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.gtag("event", "page_view", {
      "page_title": "Profile"
    })
  }, [])

  React.useEffect(() => {
    getUser().then((res) => setUser(res));
  }, []);

  const toggleAddProjectModal = React.useCallback(() => {
    setAddProjectModalOpen((prev) => !prev);
  }, []);

  const toggleEditProfileModal = () => {
    setIsEditProfileModalOpen(!isEditProfileModalOpen);
  };

  const showcases: Showcase[] = React.useMemo(() => {
    const toReturn: Showcase[] = [];
    user?.submissions.forEach((s) => s.showcases != null && toReturn.push(s.showcases));
    return toReturn;
  }, [user?.submissions]);

  if (user == null) {
    return <LoadingPage label={'Loading profile'} />;
  }

  return (
    <div className='flex flex-col gap-5 px-4'>
      <p className='text-2xl font-medium'>My Profile</p>
      <div className='flex flex-col md:flex-row gap-3 w-full'>
        <ProfileCard user={user} />
        <AboutMeCard user={user} toggleEditProfileModal={toggleEditProfileModal} />
      </div>
      <div className='flex flex-col gap-4 mt-2'>
        <div className='flex flex-row items-center justify-between'>
          <SectionTabs<Tabs> activeTab={activeTab} changeTab={setActiveTab} tabs={['Showcases', 'Portfolio']} />
          <div className='flex flex-row items-center gap-3'>
            {/*//Todo leave out until after MVP*/}
            {/*<Button*/}
            {/*    size={'sm'}*/}
            {/*    variant={'ghost'}*/}
            {/*    Icon={SvgFilter}*/}
            {/*    className={`${activeTab === 'Experience' && 'hidden'}`}*/}
            {/*>*/}
            {/*    Filters*/}
            {/*</Button>*/}
            <Button
              onClick={toggleAddProjectModal}
              size={'sm'}
              Icon={SvgFolderPlus}
              className={`${activeTab !== 'Portfolio' && 'hidden'}`}
            >
              Add Project
            </Button>
          </div>
        </div>
        <div className={`flex flex-col gap-3 ${activeTab !== 'Showcases' && 'hidden'}`}>
          <div className='hidden only:flex flex-col items-center gap-5 text-center'>
            <img
              src={require('../assets/img/morphis/morphis-new-message-notification.png')}
              className={'w-32 h-32 object-contain'}
              key={'zerostate'}
            />
            <p className='text-slate-500'>
              You haven't participated in any showcases yet. Once you add a submission it will appear here!
            </p>
            <Link to={'/discover'}>
              <Button>Find Showcases</Button>
            </Link>
          </div>
          {showcases?.map((showcase) => <ShowcaseCard key={showcase.id} showcase={showcase} />)}
        </div>
        <div
          className={`${user.submissions.length > 0 && 'grid md:grid-cols-2'} gap-3 ${activeTab !== 'Portfolio' && 'hidden'}`}
        >
          <div className='hidden only:flex flex-col items-center gap-5 text-center'>
            <img
              src={require('../assets/img/morphis/morphis-infographics-on-the-real-estate-market.png')}
              className={'w-32 h-32 object-contain'}
              key={'zerostate'}
            />
            <p className='text-slate-500'>
              You haven't linked any projects yet. Have something your proud of? Feature it on your profile!
            </p>
            <Button onClick={toggleAddProjectModal}>Get started</Button>
          </div>
          {user.submissions?.map((submission) => <ProjectCard key={submission.id} submission={submission} />)}
        </div>
      </div>
      <EditProfileModal isOpen={isEditProfileModalOpen} toggle={toggleEditProfileModal} />
      <AddProjectModal isOpen={addProjectModalOpen} toggle={toggleAddProjectModal} />
    </div>
  );
}

export default ProfilePage;
