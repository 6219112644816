import React from 'react';
import {Link} from 'react-router-dom';
import SvgLogoMark from '../../assets/brand/SvgLogoMark';
import {Button} from '../atoms/Button';
import LogOutButton from './LogOutButton';
import SvgSearch from '../icons/SvgSearch';
import SvgProfile from "../icons/SvgProfile";
import {useLocation} from "react-use";
import SvgHome from "../icons/SvgHome";

function Navbar() {
    const location = useLocation();
    return (
        <nav className='bg-white/80 backdrop-blur flex flex-row items-center justify-center py-4 px-4'>
            <div className='flex max-w-[840px] w-full flex-row items-center justify-between '>
                <Link to={'/'}>
                    <SvgLogoMark className={'w-24 md:w-36 h-12'}/>
                </Link>
                <ul className='flex flex-row items-center gap-2 md:gap-4'>
                    {location.pathname === '/discover' ?
                        <Link to={'/'}>
                            <Button Icon={SvgHome}>Back home</Button>
                        </Link>
                        :
                        (
                        <Link to={'/discover'}>
                            <Button Icon={SvgSearch}>Find Showcases</Button>
                        </Link>
                    )}
                    <Link to={'/profile'} className='block md:hidden'>
                        <Button Icon={SvgProfile} size={'icon'}/>
                    </Link>
                    <Link to={'/profile'} className='hidden md:block'>
                        <Button Icon={SvgProfile}>
                            My Profile
                        </Button>
                    </Link>
                    <LogOutButton/>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
