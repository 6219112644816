import React from 'react';

interface PropsIface {
  title: string;
  description: string;
}

const OnboardingStepHeader = ({ title, description }: PropsIface) => {
  return (
    <div className='flex flex-col gap-3 max-w-[500px]'>
      <p className='text-xl md:text-4xl font-semibold text-center'>{title}</p>
      <p className='text-sm md:text-md text-slate-500 text-center'>{description}</p>
    </div>
  );
};

export default OnboardingStepHeader;
