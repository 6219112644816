import { create } from 'zustand';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../client/supabase';
import { Enums } from '../client/supabase/schema';

export const refreshAuthUser = async (session: Session) => {
  await supabase
    .from('users')
    .select('id, onboarding_status')
    .eq('id', session.user.id)
    .single()
    .then(({ data, error }) => {
      if (error) throw error;
      useAuthUser.setState({
        session,
        loading: false,
        userId: data.id,
        onboardedState: data.onboarding_status,
      });
    });
};

interface Store {
  session: Session | null;
  loading: boolean;
  userId: string | null;
  onboardedState: Enums<'onboarding_status'> | null;
}

const initialState: Store = {
  session: null,
  loading: true,
  userId: null,
  onboardedState: null,
};

export const useAuthUser = create<Store>((set) => ({
  ...initialState,
}));

supabase.auth.onAuthStateChange((_, session) => {
  if (session != null) {
    refreshAuthUser(session);
  } else {
    useAuthUser.setState({ session, loading: false, userId: null, onboardedState: null });
  }
});
