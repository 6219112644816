import React, { useCallback, useState } from 'react';
import TextInputWrapper from '../atoms/TextInputWrapper';
import { Button } from '../atoms/Button';
import SvgLogoMark from '../../assets/brand/SvgLogoMark';
import { validateEmail, validatePassword } from '../../utils/stringValidation';
import { supabase } from '../../client/supabase';
import ForgotPasswordModal from '../modals/ForgotPasswordModal';
import SvgInfo from "../icons/SvgInfo";

const LoginView = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<{ email: string; password: string }>({ email: '', password: '' });
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handlePasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      const isEmailValid = validateEmail(email);
      const isPasswordValid = validatePassword(password);
      if (isEmailValid && isPasswordValid) {
        setLoading(true);
        const { error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error != null) {
          // TODO (jstrachan): display error message here in modal or something
          console.error(error.message);
        }
        setLoading(false);
      } else {
        if (!isEmailValid) {
          setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address.' }));
        } else if (!isPasswordValid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: 'Password must be at least 8 characters long.',
          }));
        }
      }
    },
    [email, password],
  );

  return (
    <form
      className='w-[95vw] md:w-[400px] flex flex-col gap-5 bg-white p-5 md:p-10 shadow-2xl rounded-2xl'
      onSubmit={handleSubmit}
      noValidate
    >
      <div className='flex flex-col items-center gap-3'>
        <h1 className='text-2xl font-semibold' tabIndex={-1}>
          Welcome back!
        </h1>
        <p className='text-slate-500'>Enter your credentials to login.</p>
      </div>
      <div className='flex flex-col gap-0'>
        <TextInputWrapper
          id='email'
          label={'Email'}
          value={email}
          placeholder={''}
          autoFocus={true}
          type={'email'}
          autoComplete={'email'}
          onChange={handleEmailChange}
          aria-describedby='email-error'
        />
        {errors.email && (
          <p id='email-error' className='text-red-500 text-sm'>
            {errors.email}
          </p>
        )}
      </div>
      <div className='flex flex-col gap-0'>
        <TextInputWrapper
          id='password'
          label={'Password'}
          value={password}
          placeholder={''}
          type={'password'}
          onChange={handlePasswordChange}
          aria-describedby='password-error'
        />
        {errors.password && (
          <p id='password-error' className='text-red-500 text-sm'>
            {errors.password}
          </p>
        )}
        <ForgotPasswordModal />
      </div>
      <Button type={'submit'} loading={loading}>
        Log In
      </Button>
    </form>
  );
};

export default LoginView;
