import React from 'react';
import './App.css';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Navbar from './components/navigation/Navbar';
import DiscoverPage from './pages/DiscoverPage';
import ProfilePage from './pages/ProfilePage';
import ShowcasePage from './pages/ShowcasePage';
import PublicShowcasePage from './pages/PublicShowcasePage';
import AuthPage from './pages/AuthPage';
import { useAuthUser } from './state/authUser';
import OnboardingPage from './pages/OnboardingPage';
import LoadingPage from './pages/LoadingPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import WalkthroughModal from './components/modals/WalkthroughModal';
import { finishWalkthrough } from './components/onboarding/OnboardingSocialLinks';
import UnAuthedNavbar from "./components/navigation/UnAuthedNavbar";
import PublicProfilePage from './pages/PublicProfilePage';

const Router = () => {
  const { session, loading: loadingSession, onboardedState } = useAuthUser();
  const [walkthroughOpen, setWalkthroughOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (onboardedState == 'WALKTHROUGH') {
      setWalkthroughOpen(true);
    }
  }, [onboardedState]);

  const closeWalkthrough = React.useCallback(async () => {
    await finishWalkthrough();
    setWalkthroughOpen(false);
  }, []);

  if (loadingSession) {
    return <LoadingPage />;
  }

  if (!session) {
    return (
      <div className='font-satoshi text-slate-700'>
        <div className='min-h-[100dvh] items-center flex flex-col relative'>
          <Routes>
            <Route
              element={
                <>
                  <div className='fixed w-full flex flex-col items-center z-10'>
                    <div className='w-full'>
                      <UnAuthedNavbar/>
                    </div>
                  </div>
                  <div className='flex flex-col items-center pt-28 w-full'>
                    <div className={`w-full max-w-[840px] pb-20`}>
                      <Outlet/>
                    </div>
                  </div>
                </>
              }
            >
              <Route path='/showcase/:showcaseId' element={<PublicShowcasePage/>}/>
              <Route path='/profile/:userId' element={<PublicProfilePage/>}/>
            </Route>
            <Route path='/authentication' element={<AuthPage />} />
            <Route path='*' element={<Navigate replace to='/authentication' />} />
          </Routes>
        </div>
      </div>
    )
  }

  if (onboardedState === 'NOT_STARTED') {
    return <OnboardingPage />;
  }

  return (
    <div className='font-satoshi text-slate-700'>
      {/*<ScrollToTop/>*/}
      <div className='min-h-[100dvh] items-center flex flex-col relative'>
        <Routes>
          <Route
            element={
              <>
                <div className='fixed w-full flex flex-col items-center z-10'>
                  <div className='w-full'>
                    <Navbar />
                  </div>
                </div>
                <div className='flex flex-col items-center pt-28 w-full'>
                  <div className={`w-full max-w-[840px] pb-20`}>
                    <Outlet />
                  </div>
                </div>
              </>
            }
          >
            <Route path='/' element={<HomePage />} />
            <Route path='/discover' element={<DiscoverPage />} />
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/showcase/:showcaseId' element={<ShowcasePage />} />
            <Route path='*' element={<Navigate replace to='/' />} />
          </Route>
          <Route path='/reset_password' element={<ResetPasswordPage />} />
        </Routes>
        <WalkthroughModal isOpen={walkthroughOpen} toggle={closeWalkthrough} />
      </div>
    </div>
  );
};

export default Router;
