import React from 'react';
import { cardClass } from '../../consts/styling';
import { Button } from '../atoms/Button';
import SvgEdit from '../icons/SvgEdit';
import TagComponent from '../atoms/TagComponent';
import SvgX from '../icons/SvgX';
import SvgGithub from '../icons/SvgGithub';
import { OfficePreference, Skill, User } from '../../client/supabase/types';
import { getUserSkills } from '../../client/apiClient/skills';
import { getUserOfficePreferences } from '../../client/apiClient/users';
import SvgLinkedIn from '../icons/SvgLinkedIn';
import { cleanUrl } from '../../utils/stringValidation';

interface PropsIface {
  user: User;
  toggleEditProfileModal: (() => void) | null;
}

const RemotePreferenceLabels: Record<string, string> = {
  fullyRemote: 'Fully remote',
  hybrid: 'Hybrid',
  onSite: 'On site',
};

const AboutMeCard = ({ user, toggleEditProfileModal }: PropsIface) => {
  const [skills, setSkills] = React.useState<Skill[]>([]);
  const [preferences, setPreferences] = React.useState<OfficePreference[]>([]);

  React.useEffect(() => {
    getUserSkills(user.id).then((res) => {
      setSkills(res ?? []);
    });
    getUserOfficePreferences(user.id).then((res) => {
      setPreferences(res ?? []);
    });
  }, []);

  return (
    <div className={`${cardClass} flex-1 gap-3`}>
      <div className='flex flex-row items-center justify-between'>
        <p className='text-xl font-semibold'>About Me</p>
        {!!toggleEditProfileModal && <Button Icon={SvgEdit} onClick={toggleEditProfileModal} variant={'secondary'} size={'icon'} />}
      </div>
      <div className='flex flex-col gap-2'>
        <p className='text-slate-500 text-sm'>Languages & frameworks</p>
        <div className='flex flex-row items-center gap-2 flex-wrap'>
          <p className='text-slate-500 hidden only:block'>Nothing added</p>
          {skills.map((item) => (
            <TagComponent label={item.skill_type} key={item.id} />
          ))}
        </div>
      </div>
      <div className='flex flex-col gap-2'>
        <p className='text-slate-500 text-sm'>Office preference</p>
        <div className='flex flex-row items-center gap-2 flex-wrap'>
          <p className='text-slate-500 hidden only:block'>Nothing added</p>
          {preferences.map((item) => {
            if (item.preference_type == 'REMOTE_STATUS') {
              return <TagComponent label={RemotePreferenceLabels[item.preference]} key={item.id} />;
            }
            return <TagComponent label={item.preference} key={item.id} />;
          })}
        </div>
      </div>
      <div className='flex flex-col gap-2'>
        <p className='text-slate-500 text-sm'>Social links</p>
        <div className='flex flex-row items-center gap-2 flex-wrap'>
          <p className='text-slate-500 hidden only:block'>None added</p>
          {user.Github && (
            <a href={cleanUrl(user.Github.toString())} target={'_blank'} rel={'noreferrer'}>
              <SvgGithub className='w-5 h-5 hover:text-blue-500' />
            </a>
          )}
          {user.LinkedIn && (
            <a href={cleanUrl(user.LinkedIn.toString())} target={'_blank'} rel={'noreferrer'}>
              <SvgLinkedIn className='w-5 h-5 hover:text-blue-500' />
            </a>
          )}
          {user.X && (
            <a href={cleanUrl(user.X.toString())} target={'_blank'} rel={'noreferrer'}>
              <SvgX className='w-5 h-5 hover:text-blue-500' />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutMeCard;
