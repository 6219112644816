import React from 'react';
import { Button } from '../components/atoms/Button';
import LoginView from '../components/auth/LoginView';
import SignupView from '../components/auth/SignupView';
import AuthBackdrop from '../components/auth/AuthBackdrop';
import { MeshGradientRenderer } from '@johnn-e/react-mesh-gradient';
import SvgLogoMark from '../assets/brand/SvgLogoMark';

const AuthPage = () => {
  const [activeView, setActiveView] = React.useState('signIn');

  const switchActiveView = () => {
    setActiveView(activeView === 'signIn' ? 'signUp' : 'signIn');
  };

  return (
    <div className='flex font-satoshi relative flex-col items-center justify-center min-h-[100vh] w-[100vw] overflow-y-scroll overflow-x-hidden p-5 gap-5'>
      <MeshGradientRenderer
        className='z-[100] opacity-20 absolute top-0 left-0 right-0 bottom-0'
        colors={['#ea66c3', '#6EC3F4', '#ff7575', '#d5ae13', '#6095fe']}
      />
      <AuthBackdrop />
      <SvgLogoMark aria-hidden='true' className={'w-32 h-12'} />
      {activeView === 'signIn' ? <LoginView /> : <SignupView />}
      <div className='flex flex-row items-center justify-center w-full  gap-2'>
        <p>{activeView !== 'signIn' ? 'Already have an account?' : "Don't have an account?"}</p>
        <Button onClick={switchActiveView} variant={'link'} size={'link'}>
          {activeView === 'signIn' ? 'Sign up' : 'Log In'}
        </Button>
      </div>
    </div>
  );
};

export default AuthPage;
