import React from 'react';
import {Button} from '../atoms/Button';
import ModalComponent from '../atoms/ModalComponent';
import SvgLightbulb from '../icons/SvgLightbulb';
import SvgPersonTall from '../icons/SvgPersonTall';
import SvgTreasureMap from '../icons/SvgTreasureMap';

interface WalkthroughModalProps {
    isOpen: boolean;
    toggle: () => void;
}

const WalkthroughModal: React.FC<WalkthroughModalProps> = ({isOpen, toggle}) => {
    return (
        <ModalComponent size={'lg'} isOpen={isOpen} toggle={toggle}>
            <div className='relative'>
                <img
                    alt={'bg'}
                    className={'absolute w-full h-full scale-125 top-0 left-0 right-0 bottom-0 opacity-30 z-[0]'}
                    src={require('../../assets/img/morphis/morphis-abstract-orange-and-blue-background-with-blurry-elements.png')}
                />
                <div className='mx-auto flex flex-col mt-8 gap-3 text-center relative z-[1]'>
                    <p className='text-3xl font-bold'>Welcome to 10Fold!</p>
                    <p>Heres what you need to know to get started:</p>
                </div>
                <div
                    className='w-full max-h-[60vh] overflow-y-scroll flex relative z-[1] flex-col items-start mt-8 gap-5'>
                    <div className='flex flex-col gap-1'>
                        <div className='flex flex-row text-blue-500 items-center gap-2'>
                            <SvgTreasureMap/>
                            <p className='text-lg font-bold'>Completing your first showcase</p>
                        </div>
                        <p className='ml-8'>
                            Showcases are the best way for you to stand out! Use the 'Find showcases' button to search
                            through coding
                            challenges and potential job openings, pick one you like and submit a response. Companies
                            will review and
                            respond on a rolling basis.
                        </p>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <div className='flex flex-row text-blue-500 items-center gap-2'>
                            <SvgPersonTall/>
                            <p className='text-lg font-bold'>Get the most out of your profile</p>
                        </div>
                        <p className='ml-8'>
                            Your profile is where you're able to show of what makes you different. Employers will see
                            your profile
                            when evaluating, so the more projects you include and showcases you participate in the
                            better!
                        </p>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <div className='flex flex-row text-blue-500 items-center gap-2'>
                            <SvgLightbulb/>
                            <p className='text-lg font-bold'>Showcases vs projects</p>
                        </div>
                        <p className='ml-8'>
                            Showcases are challenges directly tied to open positions and potential job opportunities. As
                            such,
                            showcases are the most effective way to show potential employers you have what it takes for
                            the job.
                        </p>
                        <p className='ml-8'>
                            Projects serve as your opportunity to feature your skills outside of showcases. Adding past
                            (or current)
                            projects to your profile is a great way to bolster your profile.
                        </p>
                    </div>
                </div>
                <div className='flex flex-row justify-center z-[20] relative gap-3 mt-8'>
                    <Button onClick={toggle}>Got it!</Button>
                </div>
            </div>
        </ModalComponent>
    );
};

export default WalkthroughModal;
