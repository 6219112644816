import React, { useEffect, useRef, useState } from 'react';
import ModalComponent from '../atoms/ModalComponent';
import TextInputWrapper from '../atoms/TextInputWrapper';
import SvgSearch from '../icons/SvgSearch';
import { Button } from '../atoms/Button';
import TagComponent from '../atoms/TagComponent';
import { Identified } from '../../client/supabase/types';

interface PropsIface<T> {
  isOpen: boolean;
  toggle: () => void;
  description?: string;
  setTags: React.Dispatch<React.SetStateAction<T[]>>;
  tags: T[];
  labelFetcher: (tag: T) => string;
  options: T[];
  topOptions?: T[];
}

const SelectTagsModal = <T extends Identified>({
  isOpen,
  toggle,
  tags,
  setTags,
  options,
  topOptions,
  description,
  labelFetcher,
}: PropsIface<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');

  const tagIdSet = React.useMemo(() => {
    return new Set(tags.map((t) => t.id));
  }, [tags]);

  const availableTags = React.useMemo(() => {
    if (search === '') {
      return options.filter((tag) => !tagIdSet.has(tag.id));
    } else {
      return options.filter(
        (tag) => !tagIdSet.has(tag.id) && labelFetcher(tag).toLowerCase().includes(search.toLowerCase()),
      );
    }
  }, [search, tagIdSet, options, labelFetcher]);

  const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const addTagToUser = React.useCallback(
    (tag: T) => {
      setTags([...tags, tag]);
    },
    [setTags, tags],
  );

  const removeTagFromUser = React.useCallback(
    (tag: T) => {
      setTags(tags.filter((t) => t.id != tag.id));
    },
    [setTags, tags],
  );

  const resetAndClose = React.useCallback(() => {
    setSearch('');
    toggle();
  }, [toggle]);

  return (
    <ModalComponent
      isOpen={isOpen}
      header={'Set tags'}
      description={description}
      headerToggle
      toggle={toggle}
      size='md'
    >
      <TextInputWrapper
        ref={inputRef}
        Icon={SvgSearch}
        type='text'
        placeholder={'Search tags'}
        value={search}
        onChange={handleInputChange}
        className='outline-none border-b-2 border-blue-400'
      />
      <div className='flex flex-row w-full overflow-x-scroll pb-2 gap-3 mt-5'>
        {/* Selected tags */}
        {tags.map((tag, index) => (
          <TagComponent
            label={labelFetcher(tag)}
            key={tag.id}
            removable={true}
            onClick={() => removeTagFromUser(tag)}
          />
        ))}
      </div>
      <div className='w-full h-[1px] bg-slate-100 mt-3' />
      <div className='h-[40vh] overflow-y-scroll pb-3 pt-3'>
        {search.length === 0 && <p className='text-slate-500'>Popular</p>}
        {topOptions && (
          <div className='flex flex-row gap-3 flex-wrap mt-2 mb-5'>
            {search === '' &&
              topOptions
                .filter((tag) => !tagIdSet.has(tag.id))
                .map((tag, index) => (
                  <button
                    onClick={() => addTagToUser(tag)}
                    key={`popular-${index}`}
                    className='flex hover:bg-gray-100 items-center gap-1 border border-slate-200 text-slate-500 rounded-full px-2 py-1'
                  >
                    <span>{labelFetcher(tag)}</span>
                    <div>+</div>
                  </button>
                ))}
          </div>
        )}
        {search.length === 0 && !!topOptions && <p className='text-slate-500'>All</p>}
        <div className='flex flex-row gap-3 flex-wrap mt-2'>
          {/* Library of tags to pick from */}
          {availableTags.map((tag, index) => (
            <button
              onClick={() => addTagToUser(tag)}
              key={index}
              className='flex items-center gap-1 border border-slate-200 text-slate-500 rounded-full px-2 py-1'
            >
              <span>{labelFetcher(tag)}</span>
              <div>+</div>
            </button>
          ))}
        </div>
      </div>
      <div className='flex flex-row justify-end gap-3'>
        <Button variant={'default'} onClick={resetAndClose}>
          Close
        </Button>
      </div>
    </ModalComponent>
  );
};

export default SelectTagsModal;
