import React from 'react';
import ProfileCard from '../components/cards/ProfileCard';
import SectionTabs from '../components/atoms/SectionTabs';
import ProjectCard from '../components/cards/ProjectCard';
import { getUser } from '../client/apiClient/users';
import AboutMeCard from '../components/cards/AboutMeCard';
import { useParams } from 'react-router-dom';
import { Showcase, User } from '../client/supabase/types';
import ShowcaseCard from '../components/cards/ShowcaseCard';
import LoadingPage from './LoadingPage';

type Tabs = 'Showcases' | 'Portfolio' | 'Experience';
type ProfileParams = {
    userId: string;
  };

function PublicProfilePage() {
  const { userId } = useParams<ProfileParams>();
  const [activeTab, setActiveTab] = React.useState<Tabs>('Showcases');
  const [user, setUser] = React.useState<User | undefined>();
  React.useEffect(() => {
    getUser(userId).then((res) => setUser(res));
  }, []);

  const showcases: Showcase[] = React.useMemo(() => {
    const toReturn: Showcase[] = [];
    user?.submissions.forEach((s) => s.showcases != null && toReturn.push(s.showcases));
    return toReturn;
  }, [user?.submissions]);

  if (user == null) {
    return <LoadingPage label={'Loading profile'} />;
  }

  return (
    <div className='flex flex-col gap-5 px-4'>
      <p className='text-2xl font-medium'>My Profile</p>
      <div className='flex flex-col md:flex-row gap-3 w-full'>
        <ProfileCard user={user} />
        <AboutMeCard user={user} toggleEditProfileModal={null} />
      </div>
      <div className='flex flex-col gap-4 mt-2'>
        <div className='flex flex-row items-center justify-between'>
          <SectionTabs<Tabs> activeTab={activeTab} changeTab={setActiveTab} tabs={['Showcases', 'Portfolio']} />
        </div>
        <div className={`flex flex-col gap-3 ${activeTab !== 'Showcases' && 'hidden'}`}>
          <div className='hidden only:flex flex-col items-center gap-5 text-center'>
            <img
              src={require('../assets/img/morphis/morphis-new-message-notification.png')}
              className={'w-32 h-32 object-contain'}
              key={'zerostate'}
            />
            <p className='text-slate-500'>
              {user.first_name} hasn't participated in any showcases yet.
            </p>
          </div>
          {showcases?.map((showcase) => <ShowcaseCard key={showcase.id} showcase={showcase} />)}
        </div>
        <div
          className={`${user.submissions.length > 0 && 'grid md:grid-cols-2'} gap-3 ${activeTab !== 'Portfolio' && 'hidden'}`}
        >
          <div className='hidden only:flex flex-col items-center gap-5 text-center'>
            <img
              src={require('../assets/img/morphis/morphis-infographics-on-the-real-estate-market.png')}
              className={'w-32 h-32 object-contain'}
              key={'zerostate'}
            />
            <p className='text-slate-500'>
              {user.first_name} hasn't linked any projects yet. Have something your proud of? Feature it on your profile!
            </p>
          </div>
          {user.submissions?.map((submission) => <ProjectCard key={submission.id} submission={submission} />)}
        </div>
      </div>
    </div>
  );
}

export default PublicProfilePage;
