import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={24}
      viewBox='0 -960 960 960'
      width={24}
      fill={'currentColor'}
      {...props}
    >
      <path d='M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160zm0-80h640v-440H160v440zm240-520h160v-80H400v80zM160-200v-440 440z' />
    </svg>
  );
};

export default SvgComponent;
