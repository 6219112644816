export const fw_lang_tags = [
  'JavaScript',
  'TypeScript',
  'Python',
  'Java',
  'C#',
  'PHP',
  'Ruby',
  'Go',
  'Kotlin',
  'Swift',
  'React',
  'Vue.js',
  'Angular',
  'Node.js',
  'Django',
  'Flask',
  'Spring Boot',
  'ASP.NET',
  'Ruby on Rails',
  'Laravel',
  'Express',
  'Meteor',
  'Svelte',
  'Next.js',
  'Nuxt.js',
  'Gatsby',
  'GraphQL',
  'Redux',
  'MobX',
  'VueX',
  'Tailwind CSS',
  'Bootstrap',
  'Material UI',
  'SASS',
  'LESS',
  'Docker',
  'Kubernetes',
  'AWS',
  'Azure',
  'Google Cloud',
  'MongoDB',
  'PostgreSQL',
  'MySQL',
  'SQLite',
  'Firebase',
  'Elasticsearch',
  'Redis',
  'Apache Kafka',
  'RabbitMQ',
  'Socket.IO',
];

export const top_fw_lang_tags = [
  'JavaScript',
  'Python',
  'React',
  'Node.js',
  'Django',
  'Angular',
  'Vue.js',
  'TypeScript',
  'AWS',
  'Docker',
];

export const topUSCities = [
  { id: '0', city: 'New York', state: 'NY' },
  { id: '1', city: 'Los Angeles', state: 'CA' },
  { id: '2', city: 'Chicago', state: 'IL' },
  { id: '3', city: 'Houston', state: 'TX' },
  { id: '4', city: 'Phoenix', state: 'AZ' },
  { id: '5', city: 'Philadelphia', state: 'PA' },
  { id: '6', city: 'San Antonio', state: 'TX' },
  { id: '7', city: 'San Diego', state: 'CA' },
  { id: '8', city: 'Dallas', state: 'TX' },
  { id: '9', city: 'San Jose', state: 'CA' },
  { id: '10', city: 'Austin', state: 'TX' },
  { id: '11', city: 'Jacksonville', state: 'FL' },
  { id: '12', city: 'Fort Worth', state: 'TX' },
  { id: '13', city: 'Columbus', state: 'OH' },
  { id: '14', city: 'Charlotte', state: 'NC' },
  { id: '15', city: 'San Francisco', state: 'CA' },
  { id: '16', city: 'Indianapolis', state: 'IN' },
  { id: '17', city: 'Seattle', state: 'WA' },
  { id: '18', city: 'Denver', state: 'CO' },
  { id: '19', city: 'Washington', state: 'DC' },
  { id: '20', city: 'Boston', state: 'MA' },
  { id: '21', city: 'El Paso', state: 'TX' },
  { id: '22', city: 'Detroit', state: 'MI' },
  { id: '23', city: 'Nashville', state: 'TN' },
  { id: '24', city: 'Portland', state: 'OR' },
  { id: '25', city: 'Memphis', state: 'TN' },
  { id: '26', city: 'Oklahoma City', state: 'OK' },
  { id: '27', city: 'Las Vegas', state: 'NV' },
  { id: '28', city: 'Louisville', state: 'KY' },
  { id: '29', city: 'Baltimore', state: 'MD' },
  { id: '30', city: 'Milwaukee', state: 'WI' },
  { id: '31', city: 'Albuquerque', state: 'NM' },
  { id: '32', city: 'Tucson', state: 'AZ' },
  { id: '33', city: 'Fresno', state: 'CA' },
  { id: '34', city: 'Mesa', state: 'AZ' },
  { id: '35', city: 'Sacramento', state: 'CA' },
  { id: '36', city: 'Atlanta', state: 'GA' },
  { id: '37', city: 'Kansas City', state: 'MO' },
  { id: '38', city: 'Colorado Springs', state: 'CO' },
  { id: '39', city: 'Miami', state: 'FL' },
  { id: '40', city: 'Raleigh', state: 'NC' },
  { id: '41', city: 'Omaha', state: 'NE' },
  { id: '42', city: 'Long Beach', state: 'CA' },
  { id: '43', city: 'Virginia Beach', state: 'VA' },
  { id: '44', city: 'Oakland', state: 'CA' },
  { id: '45', city: 'Minneapolis', state: 'MN' },
  { id: '46', city: 'Tulsa', state: 'OK' },
  { id: '47', city: 'Arlington', state: 'TX' },
  { id: '48', city: 'Tampa', state: 'FL' },
  { id: '49', city: 'New Orleans', state: 'LA' },
];

export const topCities = [
  { id: 'New York, NY', city: 'New York, NY' },
  { id: 'Los Angeles, CA', city: 'Los Angeles, CA' },
  { id: 'Chicago, IL', city: 'Chicago, IL' },
  { id: 'Houston, TX', city: 'Houston, TX' },
  { id: 'Phoenix, AZ', city: 'Phoenix, AZ' },
  { id: 'Philadelphia, PA', city: 'Philadelphia, PA' },
  { id: 'San Antonio, TX', city: 'San Antonio, TX' },
  { id: 'San Diego, CA', city: 'San Diego, CA' },
  { id: 'Dallas, TX', city: 'Dallas, TX' },
  { id: 'San Jose, CA', city: 'San Jose, CA' },
  { id: 'Austin, TX', city: 'Austin, TX' },
  { id: 'Jacksonville, FL', city: 'Jacksonville, FL' },
  { id: 'Fort Worth, TX', city: 'Fort Worth, TX' },
  { id: 'Columbus, OH', city: 'Columbus, OH' },
  { id: 'Charlotte, NC', city: 'Charlotte, NC' },
  { id: 'San Francisco, CA', city: 'San Francisco, CA' },
  { id: 'Indianapolis, IN', city: 'Indianapolis, IN' },
  { id: 'Seattle, WA', city: 'Seattle, WA' },
  { id: 'Denver, CO', city: 'Denver, CO' },
  { id: 'Washington, DC', city: 'Washington, DC' },
  { id: 'Boston, MA', city: 'Boston, MA' },
  { id: 'El Paso, TX', city: 'El Paso, TX' },
  { id: 'Detroit, MI', city: 'Detroit, MI' },
  { id: 'Nashville, TN', city: 'Nashville, TN' },
  { id: 'Portland, OR', city: 'Portland, OR' },
  { id: 'Memphis, TN', city: 'Memphis, TN' },
  { id: 'Oklahoma City, OK', city: 'Oklahoma City, OK' },
  { id: 'Las Vegas, NV', city: 'Las Vegas, NV' },
  { id: 'Louisville, KY', city: 'Louisville, KY' },
  { id: 'Baltimore, MD', city: 'Baltimore, MD' },
  { id: 'Milwaukee, WI', city: 'Milwaukee, WI' },
  { id: 'Albuquerque, NM', city: 'Albuquerque, NM' },
  { id: 'Tucson, AZ', city: 'Tucson, AZ' },
  { id: 'Fresno, CA', city: 'Fresno, CA' },
  { id: 'Mesa, AZ', city: 'Mesa, AZ' },
  { id: 'Sacramento, CA', city: 'Sacramento, CA' },
  { id: 'Atlanta, GA', city: 'Atlanta, GA' },
  { id: 'Kansas City, MO', city: 'Kansas City, MO' },
  { id: 'Colorado Springs, CO', city: 'Colorado Springs, CO' },
  { id: 'Miami, FL', city: 'Miami, FL' },
  { id: 'Raleigh, NC', city: 'Raleigh, NC' },
  { id: 'Omaha, NE', city: 'Omaha, NE' },
  { id: 'Long Beach, CA', city: 'Long Beach, CA' },
  { id: 'Virginia Beach, VA', city: 'Virginia Beach, VA' },
  { id: 'Oakland, CA', city: 'Oakland, CA' },
  { id: 'Minneapolis, MN', city: 'Minneapolis, MN' },
  { id: 'Tulsa, OK', city: 'Tulsa, OK' },
  { id: 'Arlington, TX', city: 'Arlington, TX' },
  { id: 'Tampa, FL', city: 'Tampa, FL' },
  { id: 'New Orleans, LA', city: 'New Orleans, LA' },
];
