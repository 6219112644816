import React from 'react';
import {Link} from 'react-router-dom';
import SvgLogoMark from '../../assets/brand/SvgLogoMark';
import {Button} from '../atoms/Button';
import SvgProfile from "../icons/SvgProfile";

function UnAuthedNavbar() {
    return (
        <nav className='bg-white/80 backdrop-blur flex flex-row items-center justify-center py-4 px-4'>
            <div className='flex max-w-[840px] w-full flex-row items-center justify-between '>
                <Link to={'/'}>
                    <SvgLogoMark className={'w-24 md:w-36 h-12'}/>
                </Link>
                <ul className='flex flex-row items-center gap-2 md:gap-4'>
                    <Link to={'/auth'} className=''>
                        <Button Icon={SvgProfile}>
                            Sign Up
                        </Button>
                    </Link>
                </ul>
            </div>
        </nav>
    );
}

export default UnAuthedNavbar;
