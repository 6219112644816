import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import Spinner from './Spinner';
import { SVGProps } from 'react';

type ButtonVariantProps = VariantProps<typeof buttonVariants>;
const buttonVariants = cva(
  `inline-flex items-center justify-center text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`,
  {
    variants: {
      variant: {
        default: 'bg-blue-500 hover:bg-blue-600 text-white',
        green: 'bg-green-500 hover:bg-green-600 text-black',
        purple: 'bg-purple-500 hover:bg-purple-600 text-black',
        destructive: 'bg-red-400 hover:bg-red-500 text-white',
        outline:
          'border border-input bg-background text-slate-500 hover:bg-accent hover:text-accent-foreground dark:text-darkAccent-light',
        secondary: 'bg-slate-200 text-slate-700 hover:bg-slate-300',
        ghost: 'text-slate/500 hover:text-slate-800 hover:bg-slate-100',
        ghostOverlay: 'text-white hover:bg-neutral-200/40',
        link: 'text-blue-500 hover:text-blue-800',
      },
      size: {
        default: 'h-8 text-xs md:text-sm md:h-10 px-3 md:px-5 pb-0.5',
        sm: 'h-8 px-3 text-[14.5px]',
        md: 'h-10 px-4 text-md',
        lg: 'h-12 px-5 text-lg',
        icon: 'h-8 md:h-10 w-8 md:w-10 flex flex-col items-center justify-center pb-0.5',
        link: 'pb-0.5',
      },
      radius: {
        default: 'rounded-full',
        sm: 'rounded-sm',
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      radius: 'default',
    },
  },
);

interface CustomProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean;
  Icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
}

type PropsIface = ButtonVariantProps & CustomProps;

const _Button: React.ForwardRefRenderFunction<HTMLButtonElement, React.PropsWithChildren<PropsIface>> = (
  { className, variant, radius, size, Icon, loading = false, ...props },
  ref,
) => {
  if (loading) {
    return (
      <button
        className={buttonVariants({ variant, size, radius, className }) + ' rounded-full'}
        ref={ref}
        {...props}
        disabled
      >
        <Spinner size={5} />
      </button>
    );
  }
  return (
    <button className={buttonVariants({ variant, size, radius, className }) + ' rounded-full'} ref={ref} {...props}>
      <div className='flex flex-row items-center gap-2 mt-0.5'>
        {props.children}
        {Icon != null && <Icon className={'w-4 h-4'} />}
      </div>
    </button>
  );
};

const Button = React.forwardRef(_Button);

Button.displayName = 'Button';

export { Button, buttonVariants };
