import React from 'react';
import TextInputWrapper from '../atoms/TextInputWrapper';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingButtons from './OnboardingButtons';
import Dropzone from 'react-dropzone';
import SvgImage from '../icons/SvgImage';
import TextAreaWrapper from '../atoms/TextAreaWrapper';
import { useAuthUser } from '../../state/authUser';
import { supabase } from '../../client/supabase';
import EditableProfilePicture from '../EditableProfilePicture';

const getOnboardingNameData = async () => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  const {
    data: { publicUrl },
  } = supabase.storage.from('profile_pictures').getPublicUrl(userId);
  const imagePromise = fetch(publicUrl)
    .then(res => {
      if (res.status >= 400)
        throw new Error("No pfp")
      return res;
    }).then(async (imageRes) => await imageRes.blob())
    .then((blob) => new File([blob], 'profile_picture', { type: blob.type }))
    .catch(err => undefined);
  const dataPromise = supabase
    .from('users')
    .select(
      `
      first_name,
      last_name,
      bio,
      headline
      `,
    )
    .eq('id', userId)
    .single();
  const [file, { data, error: postgresError }] = await Promise.all([imagePromise, dataPromise]);
  if (postgresError) throw postgresError;
  return { ...data, profilePic: file };
};

const setOnboardingNameData = async (
  firstName: string,
  lastName: string,
  headline: string,
  bio: string,
  profilePic: File,
) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  const { error: storageError } = await supabase.storage
    .from('profile_pictures')
    .upload(userId, profilePic, { upsert: true });
  if (storageError) {
    throw storageError;
  }

  const { error: postgresError } = await supabase
    .from('users')
    .update({ first_name: firstName, last_name: lastName, headline, bio })
    .eq('id', userId)
    .single();
  if (postgresError) throw postgresError;
};

interface PropsIface {
  goNextStep: () => void;
}

const OnboardingName = ({ goNextStep }: PropsIface) => {
  const [profilePic, setProfilePic] = React.useState<File | undefined>();
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [headline, setHeadline] = React.useState<string>('');
  const [bio, setBio] = React.useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    getOnboardingNameData().then((user) => {
      setFirstName(user?.first_name ?? '');
      setLastName(user?.last_name ?? '');
      setHeadline(user?.headline ?? '');
      setBio(user?.bio ?? '');
      setProfilePic(user?.profilePic);
    });
  }, []);

  const handleFirstNameChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setErrorMessage('');
    setFirstName(event.target.value);
  }, []);

  const handleLastNameChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setErrorMessage('');
    setLastName(event.target.value);
  }, []);

  const handleHeadlineChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setErrorMessage('');
    setHeadline(event.target.value);
  }, []);

  const handleBioChange = React.useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
    setErrorMessage('');
    setBio(event.target.value);
  }, []);

  const onDropProfileImage = React.useCallback(async (acceptedFiles: File[]) => {
    setProfilePic(acceptedFiles[0]);
  }, []);

  const profileImageUrl = React.useMemo(() => {
    if (profilePic) {
      return URL.createObjectURL(profilePic);
    }
  }, [profilePic]);

  const validateForm = React.useCallback(() => {
    if (!profilePic) {
      setErrorMessage('Please select a profile picture.');
      return false;
    }
    if (!firstName) {
      setErrorMessage('Please enter your first name.');
      return false;
    }
    if (!lastName) {
      setErrorMessage('Please enter your last name.');
      return false;
    }
    if (!headline) {
      setErrorMessage('Please enter a headline.');
      return false;
    }
    if (!bio) {
      setErrorMessage('Please enter a bio.');
      return false;
    }
    return true;
  }, [firstName, lastName, headline, bio, profilePic]);

  const handleSubmit = React.useCallback(async () => {
    if (validateForm() && profilePic != null) {
      setLoading(true);
      await setOnboardingNameData(firstName, lastName, headline, bio, profilePic);
      setLoading(false);
      goNextStep();
    }
  }, [firstName, lastName, profilePic, bio, headline, validateForm, goNextStep]);

  const disabled = !firstName || !lastName || !profilePic || !headline || !bio;

  return (
    <div className='flex flex-col gap-8'>
      <OnboardingStepHeader
        title={'Let’s set up your profile'}
        description={'Recruiters need a face to the name! Fill out your basic info to establish your profile.'}
      />
      <div className='flex flex-col gap-5 w-full items-center'>
        <EditableProfilePicture onDropProfileImage={onDropProfileImage} profileImageUrl={profileImageUrl} />
        <div className='flex flex-col gap-3 w-full'>
          <div className='flex flex-col md:flex-row items-center gap-3'>
            <TextInputWrapper
              label={'First Name'}
              value={firstName}
              placeholder={'John'}
              onChange={handleFirstNameChange}
            />
            <TextInputWrapper
              label={'Last Name'}
              value={lastName}
              placeholder={'Doe'}
              onChange={handleLastNameChange}
            />
          </div>
          <TextInputWrapper
            label={'Your headline'}
            value={headline}
            placeholder={'i.e. Senior Full Stack at Scale AI'}
            onChange={handleHeadlineChange}
          />
          <TextAreaWrapper
            label={'Your bio'}
            value={bio}
            placeholder={'Quick notes about your stack, experience, or anything else important to note...'}
            onChange={handleBioChange}
          />
        </div>
        <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>{errorMessage}</p>
      </div>

      <OnboardingButtons goNextStep={handleSubmit} backHidden nextDisabled={disabled} loading={loading} />
    </div>
  );
};

export default OnboardingName;
