import React, { FC, useMemo, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface LottieWrapperProps {
  json: object;
  width: string | number;
  height: string | number;
  className?: string;
  speed: number;
  loop: boolean;
  alwaysVisible: boolean;
}

const LottieWrapper: FC<LottieWrapperProps> = ({ json, width, height, className, speed, loop, alwaysVisible }) => {
  const [visible, setVisible] = useState(true);

  const defaultOptions = useMemo(() => {
    return {
      loop: loop,
      autoplay: true,
      animationData: json,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, [loop, json]);

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(alwaysVisible || isVisible);
  };

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility={true}
      offset={{ top: 32, bottom: 32 }}
      scrollCheck
    >
      <div className={className}>
        {visible ? (
          // <Lottie
          //     speed={speed}
          //     options={defaultOptions}
          //     width={width}
          //     height={height}
          // />
          <>
            <img alt={'loading'} src={require('../../assets/lottie/loading.gif')} className='h-12' />
          </>
        ) : (
          <div style={{ height: '300px', width: '100%' }} />
        )}
      </div>
    </VisibilitySensor>
  );
};

export default LottieWrapper;
