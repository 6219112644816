import React from 'react';

interface Step {
    label: string;
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

interface HorizontalStepperProps {
    steps: Step[];
    currentStep: number;
}

const HorizontalStepper: React.FC<HorizontalStepperProps> = ({ steps, currentStep }) => {
    return (
        <div className="flex flex-col items-center gap-4">
            <div className="flex items-center">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className="flex flex-col items-center">
                            <div
                                className={`flex items-center justify-center w-10 h-10 rounded-full ${
                                    currentStep >= index + 2 ? 'bg-blue-500' : (currentStep >= index + 1 ? 'bg-blue-500 animate-pulse' : 'bg-slate-300/50')
                                }`}
                            >
                                <step.Icon className="w-6 h-6 text-white" />
                            </div>
                            <p className={`text-center text-sm mt-2 font-medium ${currentStep >= index + 2 ? 'text-blue-500' : currentStep >= index + 1 ? 'text-slate-500' : 'text-slate-500/30'}`}>
                                {step.label}
                            </p>
                        </div>
                        {index < steps.length - 1 && (
                            <div
                                className={`flex-1 h-1.5 w-12 rounded-full overflow-hidden -mt-6 relative ${currentStep !== index + 2 && 'bg-slate-300/50'}`}>
                                <div
                                    className={`absolute top-0 left-0 h-1.5 bg-blue-500 transition-width duration-300 ${
                                        currentStep === index + 2 ? 'w-full bg-blue-500 animate-pulse' : ''
                                    }`}
                                    style={{
                                        width: currentStep !== index + 2 && currentStep > index + 1 ? '100%' : '0%'
                                    } as React.CSSProperties}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default HorizontalStepper;
