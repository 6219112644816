import React, { useEffect, useState } from 'react';
import LottieWrapper from '../components/atoms/LottieWrapper';
import { User } from '../client/supabase/types';

interface PropsIface {
  label?: string;
}

const LoadingPage = ({ label }: PropsIface) => {
  const [ellipsis, setEllipsis] = useState('');

  const animateEllipsis = () => {
    const ellipsisStates = ['', '.', '..', '...'];
    setEllipsis((prevState) => {
      const currentIndex = ellipsisStates.indexOf(prevState);
      const nextIndex = (currentIndex + 1) % ellipsisStates.length;
      return ellipsisStates[nextIndex];
    });
  };

  useEffect(() => {
    const intervalId = setInterval(animateEllipsis, 500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='flex font-satoshi bg-white text-slate-500 flex-col items-center justify-center absolute top-0 left-0 right-0 bottom-0  h-[100vh] p-5 gap-5'>
      <LottieWrapper json={{}} width={60} height={60} speed={1} loop={true} alwaysVisible={true} />
      <p>
        {label || 'Loading 10Fold'}
        {ellipsis}
      </p>
    </div>
  );
};

export default LoadingPage;
