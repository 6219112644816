import React from 'react';
import ShowcaseBody from '../components/sections/ShowcaseBody';
import { useParams } from 'react-router-dom';
import { getShowcase } from '../client/apiClient/showcases';
import { Showcase } from '../client/supabase/types';

type ShowcaseParams = {
  showcaseId: string;
};

const ShowcasePage = () => {
  const { showcaseId } = useParams<ShowcaseParams>();
  const [showcase, setShowcase] = React.useState<Showcase | undefined>();

  React.useEffect(() => {
    window.gtag("event", "page_view", {
      "page_title": "Showcase",
      "showcase_id": showcaseId
    })
  }, [showcaseId])

  React.useEffect(() => {
    if (showcaseId != null) {
      getShowcase(showcaseId).then(setShowcase);
    }
  }, [showcaseId]);

  if (showcase == null) {
    // TODO (dbhathena): add loading state
    return null;
  }

  return (
    <div className='flex flex-col gap-5 px-4 flex-1'>
      <div className='flex flex-row flex-1 text-sm md:text-2xl font-medium truncate'>
        <p className='opacity-50'>Showcases</p>
        <p className='font-medium truncate'>&nbsp;/ {showcase.title}</p>
      </div>
      <ShowcaseBody showcase={showcase} />
      {/*<ShowcaseSubmissionsSection submissions={showcase.submissions} />*/}
    </div>
  );
};

export default ShowcasePage;
