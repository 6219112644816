import React from 'react';
import TextInputWrapper from '../components/atoms/TextInputWrapper';
import { Button } from '../components/atoms/Button';
import { supabase } from '../client/supabase';
import { useNavigate } from 'react-router-dom';
import SvgLogoMark from '../assets/brand/SvgLogoMark';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handlePasswordChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setPassword(event.target.value);
  }, []);

  const handleConfirmPasswordChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setConfirmPassword(event.target.value);
  }, []);

  const onSubmit = React.useCallback(async () => {
    if (password != confirmPassword) {
      // TODO (jstrachan): set and display error
      console.log('ERROR');
      return;
    }
    setLoading(true);
    await supabase.auth.updateUser({
      password,
    });
    setLoading(false);
    navigate('/');
  }, [password, confirmPassword, navigate]);

  return (
    <div className='pt-28 gap-8 flex-col flex w-1/3'>
      <div className='flex flex-col items-center gap-2 mb-8'>
        <SvgLogoMark className='h-12 mb-6' />
        <p className='text-3xl font-bold'>Reset your 10Fold password</p>
        <p>Fill out and confirm a new password to reset your account.</p>
      </div>
      <TextInputWrapper label={'New password'} type='password' value={password} onChange={handlePasswordChange} />
      <TextInputWrapper
        label={'Confirm password'}
        type='password'
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
      />
      <Button loading={loading} onClick={onSubmit} disabled={password !== confirmPassword || password.length < 3}>
        Update password
      </Button>
    </div>
  );
};

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;
