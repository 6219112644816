import React, { useState } from 'react';
import EditProfileOverview from './edit_profile/EditProfileOverview';
import SectionTabs from '../atoms/SectionTabs';
import EditProfileSkills from './edit_profile/EditProfileSkills';
import EditProfileExperience from './edit_profile/EditProfileExperience';
import ModalComponent from '../atoms/ModalComponent';
import { Button } from '../atoms/Button';
import CollapseComponent from '../atoms/CollapseComponent';
import EditProfileLocation from './edit_profile/EditProfileLocation';
import { supabase } from '../../client/supabase';
import { useAuthUser } from '../../state/authUser';
import { getUser, updateUser } from '../../client/apiClient/users';
import { getUserSkills } from '../../client/apiClient/skills';
import { IdentifiedCity, RemotePreference, RemotePreferenceFilter, Skill } from '../../client/supabase/types';

interface PropsIface {
  isOpen: boolean;
  toggle: () => void;
}

type Tabs = 'Overview' | 'Skills & Experience' | 'Location & Office';
const EditProfileModal = ({ isOpen, toggle }: PropsIface) => {
  const userId = useAuthUser((s) => s.userId);
  const [activeTab, setActiveTab] = React.useState<Tabs>('Overview');
  const [loading, setLoading] = React.useState<boolean>(false);

  // Overview
  const [profileImage, setProfileImage] = React.useState<File | undefined>();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [headline, setHeadline] = React.useState('');
  const [bio, setBio] = React.useState('');
  const [githubLink, setGithubLink] = React.useState('');
  const [xLink, setXLink] = React.useState('');
  const [linkedInLink, setLinkedInLink] = React.useState('');

  // Skills & experience
  const [school, setSchool] = React.useState<string>('');
  const [gradYear, setGradYear] = React.useState<string>('');
  const [occupation, setOccupation] = React.useState<string>('');
  const [skills, setSkills] = React.useState<Skill[]>([]);

  // Location
  const [city, setCity] = React.useState<string>('');
  const [state, setState] = React.useState<string>('');
  const [remotePreferences, setRemotePreferences] = useState<RemotePreferenceFilter>({
    preference: {
      fullyRemote: false,
      hybrid: false,
      onSite: false,
    },
  });
  const [locationPreferences, setLocationPreferences] = useState<IdentifiedCity[]>([]);

  React.useEffect(() => {
    if (userId != null) {
      Promise.all([getUser(), getUserSkills()]).then(([userData, skillsData]) => {
        setFirstName(userData?.first_name ?? '');
        setLastName(userData?.last_name ?? '');
        setHeadline(userData?.headline ?? '');
        setBio(userData?.bio ?? '');
        setGithubLink(userData?.Github?.toString() ?? '');
        setXLink(userData?.X?.toString() ?? '');
        setLinkedInLink(userData?.LinkedIn?.toString() ?? '');

        setSchool(userData?.school ?? '');
        setGradYear(userData?.graduation_year ?? '');
        setOccupation(userData?.occupation ?? '');
        setSkills(skillsData ?? []);

        setCity(userData?.city ?? '');
        setState(userData?.state ?? '');
        const preferences: RemotePreference = {
          fullyRemote: false,
          hybrid: false,
          onSite: false,
        };
        userData?.office_preferences
          .filter((pref) => pref.preference_type == 'REMOTE_STATUS')
          .forEach((pref) => {
            if (pref.preference in preferences) {
              preferences[pref.preference as keyof RemotePreference] = true;
            }
          });
        setRemotePreferences({ preference: preferences });
        const cities =
          userData?.office_preferences
            .filter((pref) => pref.preference_type == 'LOCATION')
            .map((pref) => pref.preference) ?? [];
        setLocationPreferences(cities.map((city) => ({ id: city, city })));
      });
    }
  }, [userId]);

  const handleCheckboxChange = React.useCallback(
    (filterKey: keyof RemotePreferenceFilter, name: string, checked: boolean) => {
      setRemotePreferences((prev) => {
        return {
          ...prev,
          [filterKey]: {
            ...prev[filterKey],
            [name]: checked,
          },
        };
      });
    },
    [],
  );

  const typeCheckboxes = React.useMemo(() => {
    return [
      { name: 'fullyRemote', label: 'Fully remote', checked: remotePreferences.preference.fullyRemote },
      { name: 'hybrid', label: 'Hybrid', checked: remotePreferences.preference.hybrid },
      { name: 'onSite', label: 'On site', checked: remotePreferences.preference.onSite },
    ];
  }, [remotePreferences]);

  const onSubmit = React.useCallback(async () => {
    setLoading(true);
    const socials: Record<string, string> = {};
    if (!!githubLink) {
      if (!githubLink.includes('github.com')) {
        setLoading(false);
        return;
      }
      socials.Github = githubLink;
    }
    if (!!xLink) {
      if (!xLink.includes('twitter.com') && !xLink.includes('x.com')) {
        setLoading(false);
        return;
      }
      socials.X = xLink;
    }
    if (!!linkedInLink) {
      if (!linkedInLink.includes('linkedin.com')) {
        setLoading(false);
        return;
      }
      socials.LinkedIn = linkedInLink;
    }
    const skillsIds = skills.map((s) => s.id);
    const remotePrefs = Object.entries(remotePreferences.preference)
      .filter(([_, selected]) => selected)
      .map((p) => p[0]);
    const locationPrefs = locationPreferences.map((city) => city.city);
    await updateUser(
      firstName,
      lastName,
      headline,
      bio,
      socials,
      school,
      gradYear,
      occupation,
      skillsIds,
      city,
      state,
      remotePrefs,
      locationPrefs,
      profileImage,
    );
    window.gtag("event", "profile_edited");
    setLoading(false);
    toggle();
    window.location.reload();
  }, [
    firstName,
    lastName,
    headline,
    bio,
    githubLink,
    xLink,
    linkedInLink,
    school,
    gradYear,
    occupation,
    skills,
    city,
    state,
    remotePreferences,
    locationPreferences,
    profileImage,
    toggle,
  ]);

  return (
    <ModalComponent
      isOpen={isOpen}
      header={'Edit your profile'}
      description={'The stronger your profile is, the more you stand out'}
      headerToggle
      toggle={toggle}
      size='lg'
    >
      <div className='flex flex-col gap-3 text-slate-700'>
        <SectionTabs<Tabs>
          activeTab={activeTab}
          changeTab={setActiveTab}
          tabs={['Overview', 'Skills & Experience', 'Location & Office']}
        />
        <div className='h-[50vh] md:h-[60vh] overflow-y-scroll'>
          {activeTab === 'Overview' && (
            <EditProfileOverview
              profileImage={profileImage}
              setProfileImage={setProfileImage}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              headline={headline}
              setHeadline={setHeadline}
              bio={bio}
              setBio={setBio}
              githubLink={githubLink}
              setGithubLink={setGithubLink}
              xLink={xLink}
              setXLink={setXLink}
              linkedInLink={linkedInLink}
              setLinkedInLink={setLinkedInLink}
            />
          )}
          {activeTab === 'Skills & Experience' && (
            <EditProfileSkills
              school={school}
              setSchool={setSchool}
              gradYear={gradYear}
              setGradYear={setGradYear}
              occupation={occupation}
              setOccupation={setOccupation}
              selectedSkills={skills}
              setSelectedSkills={setSkills}
            />
          )}
          {activeTab === 'Location & Office' && (
            <EditProfileLocation
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              typeCheckboxes={typeCheckboxes}
              handleCheckboxChange={handleCheckboxChange}
              cities={locationPreferences}
              setCities={setLocationPreferences}
            />
          )}
          {/*{activeTab === 'Experience' && <EditProfileExperience/>}*/}
        </div>
        <div className='flex flex-row justify-end gap-3'>
          <Button variant={'secondary'} onClick={toggle}>
            Cancel
          </Button>
          <Button loading={loading} onClick={onSubmit}>
            Save Changes
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default EditProfileModal;
