export const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const validatePassword = (password: string) => {
  return password.length >= 8;
};

export const cleanUrl = (url: string) => {
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  }
  return '//' + url;
};
