import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={24}
      viewBox='0 -960 960 960'
      width={24}
      {...props}
      fill={'currentColor'}
    >
      <path d='M480-800q-33 0-56.5-23.5T400-880q0-33 23.5-56.5T480-960q33 0 56.5 23.5T560-880q0 33-23.5 56.5T480-800zM360-240v-440q-49-4-99-11t-98-18q-17-4-27.5-19t-5.5-32q5-17 21-25t34-4q70 15 145.5 22t149.5 7q74 0 149.5-7T775-789q18-4 34 4t21 25q5 17-5.5 32T797-709q-48 11-98 18t-99 11v440q0 17-11.5 28.5T560-200q-17 0-28.5-11.5T520-240v-200h-80v200q0 17-11.5 28.5T400-200q-17 0-28.5-11.5T360-240zM320 0q-17 0-28.5-11.5T280-40q0-17 11.5-28.5T320-80q17 0 28.5 11.5T360-40q0 17-11.5 28.5T320 0zm160 0q-17 0-28.5-11.5T440-40q0-17 11.5-28.5T480-80q17 0 28.5 11.5T520-40q0 17-11.5 28.5T480 0zm160 0q-17 0-28.5-11.5T600-40q0-17 11.5-28.5T640-80q17 0 28.5 11.5T680-40q0 17-11.5 28.5T640 0z' />
    </svg>
  );
};

export default SvgComponent;
