import React from 'react';
import { createPortal } from 'react-dom';

interface PropsIface {
  node?: Element | DocumentFragment;
}

const Portal = ({ node, children }: React.PropsWithChildren<PropsIface>) => {
  const defaultNode = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    return () => {
      if (defaultNode.current != null) {
        document.body.removeChild(defaultNode.current);
      }
      defaultNode.current = null;
    };
  }, []);

  if (node != null) {
    return createPortal(children, node);
  }

  if (defaultNode.current == null) {
    defaultNode.current = document.createElement('div');
    document.body.appendChild(defaultNode.current);
    return null;
  }

  return createPortal(children, defaultNode.current);
};

export default Portal;
