import React from 'react';
import { Button } from '../atoms/Button';

interface CommonProps {
  goNextStep: () => void;
  nextDisabled?: boolean;
  finish?: boolean;
  loading?: boolean;
}

type BackProps =
  | {
      goBackStep: () => void;
      backHidden?: never | false;
    }
  | {
      goBackStep?: never;
      backHidden: true;
    };

type PropsIface = CommonProps & BackProps;

const OnboardingButtons = ({
  goNextStep,
  goBackStep,
  nextDisabled = false,
  backHidden = false,
  finish = false,
  loading = false,
}: PropsIface) => {
  return (
    <div className='flex flex-col gap-3'>
      <Button variant={'default'} disabled={nextDisabled} onClick={goNextStep} loading={loading}>
        {finish ? 'Finish' : 'Continue'}
      </Button>
      {!backHidden && (
        <Button variant={'ghost'} onClick={goBackStep} disabled={loading}>
          Go Back
        </Button>
      )}
    </div>
  );
};

export default OnboardingButtons;
