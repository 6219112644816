import React, {AnchorHTMLAttributes, DetailedHTMLProps} from 'react';
import {textLight} from '../../consts/styling';
import {Button} from '../atoms/Button';
import ShowcaseJobOpeningItem from './ShowcaseJobOpeningItem';
import AddSubmissionModal from '../modals/AddSubmissionModal';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {Components} from 'rehype-react';
import {PublicShowcase, Showcase} from '../../client/supabase/types';
import SvgDone from '../icons/SvgDone';
import {Link} from "react-router-dom";

interface PropsIface {
    showcase: Showcase | PublicShowcase;
    isPublic?: boolean
}

const ShowcaseBody = ({showcase, isPublic}: PropsIface) => {
    const [submissionModalOpen, setSubmissionModalOpen] = React.useState<boolean>(false);

    const toggleSubmissionModal = React.useCallback(() => {
        setSubmissionModalOpen((prev) => !prev);
    }, []);

    const MarkdownComponents: Partial<Components> = {
        a: ({
                href,
                children,
                ...props
            }: DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => (
            <a href={href} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline" {...props}>
                {children}
            </a>
        ),
    };

    const loggedIn = "submissions" in showcase;
    const submissions = loggedIn ? showcase.submissions : [];
    return (
        <div className='flex flex-col gap-5'>
            <img alt={'cover'} src={showcase.cover_url}
                 className='w-full h-28 md:h-44 rounded-xl bg-slate-50 object-cover'/>
            <div className='flex flex-col gap-3'>
                <div className='flex flex-row gap-3'>
                    <div className='flex flex-col mr-auto'>
                        <p className={`text-md ${textLight}`}>{showcase.type} Showcase</p>
                        <p className='text-2xl font-semibold'>{showcase.title}</p>
                    </div>
                </div>
            </div>
            {submissions.length > 0 && loggedIn && (
                <div
                    className='bg-blue-500/10 px-2 md:px-5 pr-3 text-blue-500 rounded-full py-2 text-center font-bold flex flex-row items-center justify-between gap-3'>
                    <div className='flex flex-row items-center gap-1 md:gap-3 text-left text-xs md:text-sm'>
                        <SvgDone className={'w-4 h-4 ml-2 md:ml-0 md:w-5 md:h-5'}/>
                        <p className='flex flex-1'>You've already submit a solution to this showcase.</p>
                    </div>
                    {submissions.map((submission) => {
                        return (
                            <a href={submission.project_url} target={'_blank'} rel={'noreferrer'}>
                                <Button>View</Button>
                            </a>
                        );
                    })}
                </div>
            )}
            {showcase.job_openings != null && showcase.job_openings.length > 0 && (
                <div className='flex flex-col gap-3'>
                    {showcase.job_openings.some(opening => !opening.sponsored) ? null : <p>Posted by:</p>}
                    {showcase.job_openings.map((opening) => (
                        <ShowcaseJobOpeningItem opening={opening} id={opening.id} key={opening.id}/>
                    ))}
                    {isPublic &&
                        <Link to={'/auth'} className='mx-auto'>
                            <Button Icon={SvgDone}>
                                Apply Now
                            </Button>
                        </Link>
                    }
                    {submissions.length === 0 && loggedIn && (
                        <div className='w-full flex flex-row items-center justify-center gap-3'>
                            <Button onClick={toggleSubmissionModal}>Apply Now</Button>
                            <AddSubmissionModal
                                isOpen={submissionModalOpen}
                                toggle={toggleSubmissionModal}
                                showcaseId={showcase.id}
                            />
                            {/*TODO: comment in when bookmarks are finalized*/}
                            {/*<Button variant={'secondary'}>*/}
                            {/*  Save*/}
                            {/*</Button>*/}
                        </div>
                    )}
                </div>
            )}
            <div className='flex flex-col gap-3'>
                <p className='text-xl font-medium'>Showcase Details</p>

            </div>
            <Markdown
                rehypePlugins={[rehypeRaw]}
                components={MarkdownComponents}
            >
                {showcase.problem_description}
            </Markdown>
            <div className='flex flex-row items-center gap-3'>
                <p>Type</p>
                <p className={'font-medium'}>{showcase.type}</p>
            </div>
            <div className='flex flex-col md:flex-row md:items-center flex-wrap gap-3'>
                <p>Skill, Languages, & Frameworks</p>
                <div className='flex flex-row items-center gap-2 flex-wrap'>
                    {showcase.skills.length === 0 && <p className={'font-medium'}>N/A</p>}
                    {<p className={'font-medium'}>{showcase.skills.map(skill => skill.skill_type).join(', ')}</p>}
                </div>
            </div>
        </div>
    );
};

export default ShowcaseBody;
