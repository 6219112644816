import React, { useCallback } from 'react';
import TextInputWrapper from '../../atoms/TextInputWrapper';
import SvgGithub from '../../icons/SvgGithub';
import TextAreaWrapper from '../../atoms/TextAreaWrapper';
import SvgX from '../../icons/SvgX';
import EditableProfilePicture from '../../EditableProfilePicture';
import { useAuthUser } from '../../../state/authUser';
import { supabase } from '../../../client/supabase';
import SvgLinkedIn from '../../icons/SvgLinkedIn';

interface PropsIface {
  profileImage: File | undefined;
  setProfileImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  headline: string;
  setHeadline: React.Dispatch<React.SetStateAction<string>>;
  bio: string;
  setBio: React.Dispatch<React.SetStateAction<string>>;
  githubLink: string;
  setGithubLink: React.Dispatch<React.SetStateAction<string>>;
  xLink: string;
  setXLink: React.Dispatch<React.SetStateAction<string>>;
  linkedInLink: string;
  setLinkedInLink: React.Dispatch<React.SetStateAction<string>>;
}

const EditProfileOverview = ({
  profileImage,
  setProfileImage,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  bio,
  setBio,
  headline,
  setHeadline,
  githubLink,
  setGithubLink,
  xLink,
  setXLink,
  linkedInLink,
  setLinkedInLink,
}: PropsIface) => {
  const userId = useAuthUser((s) => s.userId);

  const [errorMessage, setErrorMessage] = React.useState('');

  const onImageDrop = useCallback((files: File[]) => {
    setProfileImage(files[0]);
  }, []);

  const handleFirstNameChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setFirstName(event.target.value);
    },
    [errorMessage, setFirstName],
  );

  const handleLastNameChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setLastName(event.target.value);
    },
    [errorMessage, setLastName],
  );

  const handleOnlinerChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setHeadline(event.target.value);
    },
    [setHeadline],
  );

  const handleBioChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
    (event) => {
      setBio(event.target.value);
    },
    [setBio],
  );

  const handleGithubLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setGithubLink(event.target.value);
    },
    [errorMessage, setGithubLink],
  );

  const handleXLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setXLink(event.target.value);
    },
    [errorMessage, setXLink],
  );

  const handleLinkedinLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setLinkedInLink(event.target.value);
    },
    [errorMessage, setLinkedInLink],
  );

  const profileImageUrl = React.useMemo(() => {
    // If user has selected a new image to upload, show that preview
    if (profileImage != null) {
      return URL.createObjectURL(profileImage);
    }
    // Otherwise fall back to the existing profile picture
    if (userId != null) {
      return supabase.storage.from('profile_pictures').getPublicUrl(userId).data.publicUrl;
    }
  }, [profileImage, userId]);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col items-center'>
        <EditableProfilePicture onDropProfileImage={onImageDrop} profileImageUrl={profileImageUrl} />
      </div>
      <div className='flex flex-col md:flex-row md:items-center gap-3'>
        <TextInputWrapper
          label={'First Name'}
          value={firstName}
          placeholder={'Your first name'}
          onChange={handleFirstNameChange}
          required
        />
        <TextInputWrapper
          label={'Last Name'}
          value={lastName}
          placeholder={'Your last name'}
          onChange={handleLastNameChange}
          required
        />
      </div>
      <TextInputWrapper
        label={'Your one-liner'}
        value={headline}
        placeholder={'A brief oneliner about you.'}
        onChange={handleOnlinerChange}
        required
      />
      <TextAreaWrapper
        label={'Bio'}
        value={bio}
        required
        placeholder={'A brief oneliner about you.'}
        onChange={handleBioChange}
      />
      {/*<div className='bg-red-500/10 p-5 text-center'>Add location and office preference selects, same as onboarding </div>*/}
      {/*<DropzoneUploader label={'Resume'} hasCoverImage={false} onImageUpload={handleImageUpload} />*/}
      <div className='flex flex-col gap-2'>
        <p className='block text-sm ml-0 font-medium text-slate-500 font-body'>Your other profiles</p>
        <TextInputWrapper
          value={githubLink}
          placeholder={'github.com/reallygoodcoder'}
          onChange={handleGithubLinkChange}
          required
          Icon={SvgGithub}
        />
        <TextInputWrapper
          value={xLink}
          placeholder={'x.com/reallygoodcoder'}
          onChange={handleXLinkChange}
          required
          Icon={SvgX}
        />
        <TextInputWrapper
          value={linkedInLink}
          placeholder={'linkedin.com/reallygoodcoder'}
          onChange={handleLinkedinLinkChange}
          required
          Icon={SvgLinkedIn}
        />
      </div>
    </div>
  );
};

export default EditProfileOverview;
