import React from 'react';

const FutureShowcasesNullState = () => {
  return (
    <div className='hidden only:flex flex-col items-center gap-5 mt-12'>
      <img
        src={require('../../assets/img/morphis/morphis-new-message-notification.png')}
        className={'w-44 h-44 object-contain'}
        key={'zerostate'}
      />
      <div className='text-center flex flex-col gap-3'>
        <p className='text-xl font-semibold'>Oh no, looks like there aren't any showcases available.</p>
        <p className='text-slate-500 text-center w-2/3 text-balance mx-auto'>
          Check back later to find new opportunities to showcase your skills!
        </p>
      </div>
    </div>
  );
};

export default FutureShowcasesNullState;
