import React, { useState } from 'react';
import CitySearchDropdown from '../../atoms/CitySearchDropdown';
import CheckboxGroup from '../../atoms/CheckboxGroup';
import TagsInput from '../../atoms/TagsInput';
import { topCities } from '../../../consts/tags';
import { IdentifiedCity, RemotePreferenceFilter } from '../../../client/supabase/types';

interface PropsIface {
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  handleCheckboxChange: (filterKey: keyof RemotePreferenceFilter, name: string, checked: boolean) => void;
  typeCheckboxes: { name: string; label: string; checked: boolean }[];
  cities: IdentifiedCity[];
  setCities: React.Dispatch<React.SetStateAction<IdentifiedCity[]>>;
}

const EditProfileLocation = ({
  city,
  setCity,
  state,
  setState,
  typeCheckboxes,
  handleCheckboxChange,
  cities,
  setCities,
}: PropsIface) => {
  const labelFetcher = React.useCallback((city: IdentifiedCity) => {
    return city.city;
  }, []);

  const onRemoteCheckboxChange = React.useCallback(
    (name: string, checked: boolean) => {
      handleCheckboxChange('preference', name, checked);
    },
    [handleCheckboxChange],
  );

  return (
    <div className='flex flex-col gap-5'>
      <CitySearchDropdown label={'Your current city'} city={city} state={state} setCity={setCity} setState={setState} />
      <CheckboxGroup
        label='What is your remote preference?'
        labelSize={'sm'}
        direction={'row'}
        items={typeCheckboxes}
        onChange={onRemoteCheckboxChange}
      />
      <TagsInput<IdentifiedCity>
        tags={cities}
        setTags={setCities}
        placeholder={'None added'}
        options={topCities}
        label={'Where would you like to work?'}
        labelFetcher={labelFetcher}
      />
    </div>
  );
};

export default EditProfileLocation;
