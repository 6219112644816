import React from 'react';
import RegisterForFirstShowcaseCard from '../cards/RegisterForFirstShowcaseCard';
import FinishFillingOutProfileCard from '../../components/cards/FinishFillingOutProfileCard';

function NewUserDashboard() {
    return (
        <div className={'w-full flex flex-col gap-12'}>
            <div className='flex flex-col gap-3 mt-8 text-center'>
                <p className='text-5xl text-blue-500 font-medium'>Welcome to 10Fold</p>
                <p className='text-lg text-slate-500'>
                    Get started by searching for showcases to complete or bolster your profile by adding previous
                    projects.
                </p>
            </div>
            <div className='flex flex-col md:flex-row gap-3 w-full'>
                <RegisterForFirstShowcaseCard/>
                <FinishFillingOutProfileCard/>
            </div>
        </div>
    );
}

export default NewUserDashboard;
