import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={33} height={33} viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M24.825 3.53h4.413L19.6 14.542 30.938 29.53h-8.875l-6.957-9.088-7.95 9.088H2.738l10.306-11.782L2.175 3.53h9.1l6.281 8.306 7.27-8.306zm-1.55 23.362h2.444L9.944 6.03H7.319l15.956 20.862z'
        fill='currentColor'
      />
    </svg>
  );
};

export default SvgComponent;
