import React, { useState } from 'react';
import { Button } from './Button';
import SelectTagsModal from '../modals/SelectTagsModal';
import TagComponent from './TagComponent';
import { Identified } from '../../client/supabase/types';

interface PropsIface<T> {
  label?: string;
  placeholder?: string;
  tags: T[];
  setTags: React.Dispatch<React.SetStateAction<T[]>>;
  labelFetcher: (tag: T) => string;
  options: T[];
  topOptions?: T[];
}

const TagsInput = <T extends Identified>({
  tags,
  setTags,
  label,
  placeholder,
  options,
  topOptions,
  labelFetcher,
}: PropsIface<T>) => {
  const [inputVisible, setInputVisible] = useState(false);

  const handleAddClick = React.useCallback(() => {
    setInputVisible(true);
  }, []);

  const closeTagSelectModal = React.useCallback(() => {
    setInputVisible(false);
  }, []);

  const visibleTags = React.useMemo(() => {
    return tags.map((tag, index) => {
      return <TagComponent label={labelFetcher(tag)} />;
    });
  }, [tags, labelFetcher]);

  return (
    <div className='flex flex-col gap-3'>
      <p className={`text-slate-500 text-sm font-medium`}>{label}</p>
      <div className='flex flex-row flex-wrap items-center gap-2'>
        {visibleTags.length === 0 && <p className='text-slate-500'>{placeholder || 'No tags added'}</p>}
        {visibleTags}
        <Button onClick={handleAddClick} disabled={inputVisible} size={'sm'}>
          {inputVisible ? 'Editing' : tags?.length > 0 ? 'Edit' : '+ Add new'}
        </Button>
      </div>
      <SelectTagsModal<T>
        tags={tags}
        setTags={setTags}
        options={options}
        topOptions={topOptions}
        isOpen={inputVisible}
        toggle={closeTagSelectModal}
        description={label}
        labelFetcher={labelFetcher}
      />
    </div>
  );
};

export default TagsInput;
