import React from 'react';
import Portal from './PortalComponent';
import { wait } from '@testing-library/user-event/dist/utils';
import SvgClose from '../icons/SvgClose';
import '../../styles/ModalComponent.css';
import ConfettiComponent from './ConfettiComponent';

interface PropsIface {
  isOpen: boolean;
  toggle?: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  header?: string;
  headerToggle?: boolean;
  FooterComponent?: React.ReactNode;
  footerAlignment?: 'left' | 'center' | 'right';
  zIndex?: number;
  width?: React.CSSProperties['width'];
  minWidth?: React.CSSProperties['minWidth'];
  node?: Element;
  preventEscape?: boolean;
  backdropClassName?: string;
  modalClassName?: string;
  backgroundColor?: React.CSSProperties['backgroundColor'];
  confetti?: boolean;
  noPadding?: boolean;
  description?: string;
}

const ModalComponent = ({
  isOpen,
  toggle,
  size = 'md',
  header,
  headerToggle = false,
  FooterComponent,
  footerAlignment = 'center',
  zIndex,
  width,
  minWidth,
  node,
  preventEscape = false,
  backdropClassName = '',
  modalClassName = '',
  backgroundColor,
  confetti = false,
  noPadding = false,
  description,
  children,
}: React.PropsWithChildren<PropsIface>) => {
  const [internalIsOpen, setInternalIsOpen] = React.useState<boolean>(false);
  const [modalOpacity, setModalOpacity] = React.useState<number>(0);
  const [triggeringElement, setTriggeringElement] = React.useState<HTMLElement | null>(null);
  const [element, setElement] = React.useState<HTMLDivElement | null>(null);
  const [mouseDownElement, setMouseDownElement] = React.useState<EventTarget | null>(null);

  const dialogRef = React.useRef<HTMLDivElement>(null);
  const initialMount = React.useRef<boolean>(true);

  const animateOpen = React.useCallback(() => {
    setInternalIsOpen(true);
    document.body.style.overflowY = 'hidden';
    wait(50).then(() => {
      setModalOpacity(1);
    });
  }, []);

  const animateClosed = React.useCallback(() => {
    setModalOpacity(0);
    document.body.style.overflowY = 'scroll';
    wait(300).then(() => {
      setInternalIsOpen(false);
    });
  }, []);

  const manageFocusAfterClose = React.useCallback(() => {
    if (triggeringElement) {
      if (triggeringElement.focus) {
        triggeringElement.focus();
      }
      setTriggeringElement(null);
    }
  }, [triggeringElement]);

  const init = React.useCallback(() => {
    try {
      setTriggeringElement(document.activeElement as HTMLElement);
    } catch (err) {
      setTriggeringElement(null);
    }

    if (!element) {
      const newElement: HTMLDivElement = document.createElement('div');
      newElement.setAttribute('tabindex', '-1');

      newElement.style.position = 'relative';
      newElement.style.zIndex = zIndex != null ? `${zIndex}` : '1050';
      if (node) {
        node.appendChild(newElement);
      } else {
        const mountContainer = document.querySelectorAll('body')[0];
        mountContainer.appendChild(newElement);
      }
      setElement(newElement);
    }
  }, [node, zIndex, element]);

  const destroy = React.useCallback(() => {
    if (element) {
      if (node) {
        node.removeChild(element);
      } else {
        const mountContainer = document.querySelectorAll('body')[0];
        mountContainer.removeChild(element);
      }
    }
    manageFocusAfterClose();
  }, [element, node, manageFocusAfterClose]);

  const setFocus = React.useCallback(() => {
    dialogRef.current?.parentElement?.focus();
  }, []);

  const handleEscape = React.useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (e) => {
      if (!preventEscape && internalIsOpen && e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        animateClosed();
      }
    },
    [preventEscape, internalIsOpen, animateClosed],
  );

  const handleBackdropMouseDown = React.useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    setMouseDownElement(e.target);
  }, []);

  const handleBackdropClick = React.useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (e.target === mouseDownElement) {
        e.stopPropagation();
        const backdrop = dialogRef.current ? dialogRef.current.parentNode : null;

        if (!isOpen) return;

        if (backdrop && e.target === backdrop && toggle) {
          toggle();
          animateClosed();
        }
      }
    },
    [isOpen, toggle, animateClosed, mouseDownElement],
  );

  React.useEffect(() => {
    if (internalIsOpen && !triggeringElement) {
      setFocus();
    }
  }, [internalIsOpen, triggeringElement, setFocus]);

  React.useEffect(() => {
    if (isOpen && !internalIsOpen && initialMount.current) {
      animateOpen();
      initialMount.current = false;
    } else if (internalIsOpen && !isOpen) {
      animateClosed();
      initialMount.current = true;
    }
  }, [isOpen, internalIsOpen, animateOpen, animateClosed]);

  React.useEffect(() => {
    if (internalIsOpen && !element) {
      init();
    }
  }, [internalIsOpen, init, element]);

  React.useEffect(() => {
    return () => {
      if (element) {
        destroy();
      }
    };
  }, [element, destroy]);

  if (!!element && internalIsOpen) {
    const isModalHidden = !!element && !internalIsOpen;
    element.style.display = isModalHidden ? 'none' : 'block';

    const modalAttributes = {
      onClick: handleBackdropClick,
      onMouseDown: handleBackdropMouseDown,
      className: `modal-new-open backdrop-blur text-white ${backdropClassName}`,
      onKeyUp: handleEscape,
      role: 'dialog',
      tabIndex: -1,
    };

    return (
      <Portal node={element}>
        <div>
          <div {...modalAttributes} style={{ opacity: modalOpacity, left: 0 }}>
            {confetti && <ConfettiComponent />}
            <div ref={dialogRef}>
              <div
                className={`modal-content overflow-hidden relative font-satoshi text-slate-700  modal-content-${size} ${modalClassName} rounded-3xl `}
                role='document'
                style={{
                  transition: '100ms ease',
                  padding: noPadding ? 0 : undefined,
                  backgroundColor: backgroundColor,
                }}
              >
                {(header || headerToggle) && (
                  <div className={'mb-4'}>
                    <div
                      style={{ padding: noPadding ? `${24}px ${24}px 0` : undefined }}
                      className='text-lg text-slate-700 flex flex-row items-center justify-between dark:text-darkMain-lighter'
                    >
                      <div className={`text-xl font-bold`}>{header}</div>
                      {headerToggle && toggle && (
                        <div
                          className='pointer text-white/80 hover:text-white cursor-pointer'
                          onClick={() => {
                            toggle();
                            animateClosed();
                          }}
                        >
                          <SvgClose className='text-slate-500' width={24} height={24} />
                        </div>
                      )}
                    </div>
                    {description != null && <p className='text-slate-500 hover:text-slate-700 mb-2'>{description}</p>}
                  </div>
                )}
                {children}
                {FooterComponent != null && (
                  <div
                    style={{ padding: noPadding ? `0 ${24}px ${24}px` : undefined }}
                    className={
                      footerAlignment === 'right'
                        ? 'flex flex-row justify-end pt-8 items-center'
                        : 'flex flex-row justify-center pt-8 items-center'
                    }
                  >
                    {FooterComponent}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  }
  return null;
};

export default ModalComponent;
