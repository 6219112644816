import React from 'react';

export type CheckboxType<T> = {
  label: string;
  name: T;
  checked: boolean;
};

interface PropsIface<T> extends CheckboxType<T> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxItem = <T extends string>({ label, name, checked, onChange }: PropsIface<T>) => {
  return (
    <label className='flex items-center gap-2'>
      <input
        type='checkbox'
        name={name}
        checked={checked}
        onChange={onChange}
        className='form-checkbox cursor-pointer h-5 w-5 text-blue-600 rounded focus:ring-blue-500'
      />
      <span className={checked ? '' : 'text-slate-500'}>{label}</span>
    </label>
  );
};

export default CheckboxItem;
