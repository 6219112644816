import React from 'react';
import { cardClass, textLight } from '../../consts/styling';
import moment from 'moment';
import { Max2Decimals } from '../../utils/format';
import { useNavigate } from 'react-router-dom';
import { Showcase } from '../../client/supabase/types';
import TagComponent from '../atoms/TagComponent';

interface PropsIface {
  index?: number;
  showcase: Showcase;
}

const ShowcaseCard = ({ index, showcase }: PropsIface) => {
  const navigate = useNavigate();

  const startMoment = React.useMemo(() => {
    return moment(showcase.start_time);
  }, [showcase.start_time]);

  const endMoment = React.useMemo(() => {
    return moment(showcase.end_time);
  }, [showcase.end_time]);

  const durationHours = Max2Decimals(showcase.duration / 3600);

  const navigateToShowcase = React.useCallback(() => {
    navigate(`/showcase/${showcase.id}`);
  }, [showcase.id, navigate]);

  return (
    <div onClick={navigateToShowcase} className={`${cardClass} group cursor-pointer hover:bg-slate-50 gap-3 p-3`}>
      {/*{index != null && index === 0 && <Banner startTime={startMoment} />}*/}
      <div className='relative'>
        <img alt={'cover'} src={showcase.cover_url} className='w-full h-28 rounded-xl bg-slate-50 object-cover' />
        {showcase.job_openings != null && showcase.job_openings.length > 0 && (
          <div className='absolute bottom-2 right-2 bg-white/80 rounded-xl px-4 py-1 flex flex-row items-center gap-3'>
            {showcase.job_openings.some(opening => !opening.sponsored) ? null : <p className='text-xs md:text-sm font-medium'>Sponsored by:</p>}
            {showcase.job_openings.map((opening) => (
              <img key={opening.id} alt={'logo'} className='w-6 md:w-8 aspect-square object-contain' src={opening.logo_url} />
            ))}
          </div>
        )}
      </div>
      <div className='flex flex-col md:flex-row gap-3'>
        <div className='flex flex-col mr-auto'>
          <p className={`text-xs md:text-sm ${textLight}`}>{showcase.type} Showcase</p>
          <p className='text-lg font-semibold'>{showcase.title}</p>
        </div>
        <div className='flex flex-row items-center gap-8'>
          <div className='flex flex-col gap-'>
            <p className='text-xs md:text-sm text-slate-500 mb-1 md:mb-0'>Skills</p>
            <div className='flex flex-row items-center gap-2 flex-wrap'>
              <p className='text-slate-400 text-lg hidden only:block'>N/A</p>
              {showcase.skills.slice(0,3).map((item, index) => (
                <TagComponent label={item.skill_type} key={index} />
              ))}
            </div>
          </div>
          <div className='flex flex-col ml-auto'>
            <p className='text-xs md:text-sm text-slate-500'>Est time</p>
            <p className='md:text-lg font-medium'>{durationHours} hrs</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowcaseCard;
