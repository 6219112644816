import React, { useState } from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingButtons from './OnboardingButtons';
import TextInputWrapper from '../atoms/TextInputWrapper';
import SvgGithub from '../icons/SvgGithub';
import SvgX from '../icons/SvgX';
import { refreshAuthUser, useAuthUser } from '../../state/authUser';
import { supabase } from '../../client/supabase';
import SvgLinkedIn from '../icons/SvgLinkedIn';

const getOnboardingSocialData = async () => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  const { data, error } = await supabase
    .from('users')
    .select(
      `
      social_links->Github,
      social_links->X,
      social_links->LinkedIn
    `,
    )
    .eq('id', userId)
    .single();
  if (error) throw error;
  return data;
};

const setOnboardingSocialData = async (socials: Record<string, string>) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  await supabase.from('users').update({ social_links: socials }).eq('id', userId);
};

const finishOnboarding = async () => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  await supabase.from('users').update({ onboarding_status: 'WALKTHROUGH' }).eq('id', userId);
  const {
    data: { session },
  } = await supabase.auth.getSession();
  if (session != null) {
    await refreshAuthUser(session);
  }
};

export const finishWalkthrough = async () => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  await supabase.from('users').update({ onboarding_status: 'COMPLETE' }).eq('id', userId);
  const {
    data: { session },
  } = await supabase.auth.getSession();
  if (session != null) {
    await refreshAuthUser(session);
  }
};

interface PropsIface {
  goNextStep: () => void;
  goBackStep: () => void;
}

const OnboardingSocialLinks = ({ goNextStep, goBackStep }: PropsIface) => {
  const [githubLink, setGithubLink] = useState('');
  const [xLink, setXLink] = useState('');
  const [linkedInLink, setLinkedInLink] = useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    getOnboardingSocialData().then((res) => {
      setGithubLink(res?.Github?.toString() ?? '');
      setXLink(res?.X?.toString() ?? '');
      setLinkedInLink(res?.LinkedIn?.toString() ?? '');
    });
  }, []);

  const handleGithubLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setGithubLink(event.target.value);
    },
    [errorMessage],
  );

  const handleXLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setXLink(event.target.value);
    },
    [errorMessage],
  );

  const handleLinkedInLinkChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setLinkedInLink(event.target.value);
    },
    [errorMessage],
  );

  const handleSubmit = React.useCallback(async () => {
    setLoading(true);
    const socials: Record<string, string> = {};
    if (!!githubLink) {
      if (!githubLink.includes('github.com')) {
        setErrorMessage('Please enter a valid Github link');
        setLoading(false);
        return;
      }
      socials.Github = githubLink;
    }
    if (!!xLink) {
      if (!xLink.includes('twitter.com') && !xLink.includes('x.com')) {
        setErrorMessage('Please enter a valid X link');
        setLoading(false);
        return;
      }
      socials.X = xLink;
    }
    if (!!linkedInLink) {
      if (!linkedInLink.includes('linkedin.com')) {
        setErrorMessage('Please enter a valid LinkedIn link');
        setLoading(false);
        return;
      }
      socials.LinkedIn = linkedInLink;
    }
    await setOnboardingSocialData(socials);
    await finishOnboarding();
    setLoading(false);
    goNextStep();
  }, [goNextStep, githubLink, xLink, linkedInLink]);

  return (
    <div className='flex flex-col gap-4'>
      <OnboardingStepHeader
        title={'Last step! Connect your socials.'}
        description={'Add links so employers can get a better sense of you'}
      />
      <div className='flex flex-col gap-2'>
        <p className='block text-sm ml-0 font-medium text-slate-500 font-body'>Your other profiles</p>
        <TextInputWrapper
          value={githubLink}
          placeholder={'github.com/reallygoodcoder'}
          onChange={handleGithubLinkChange}
          required
          Icon={SvgGithub}
        />
        <TextInputWrapper
          value={xLink}
          placeholder={'x.com/reallygoodcoder'}
          onChange={handleXLinkChange}
          required
          Icon={SvgX}
        />
        <TextInputWrapper
          value={linkedInLink}
          placeholder={'linkedin.com/reallygoodcoder'}
          onChange={handleLinkedInLinkChange}
          required
          Icon={SvgLinkedIn}
        />
      </div>
      <p className={'text-red-500'}>{errorMessage}&nbsp;</p>
      <OnboardingButtons goNextStep={handleSubmit} backHidden={false} goBackStep={goBackStep} loading={loading} />
    </div>
  );
};

export default OnboardingSocialLinks;
