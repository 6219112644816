import React, { SVGProps } from 'react';
import SvgInfo from '../icons/SvgInfo';

interface PropsIface extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  Icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  label?: string;
  helperTip?: string;
  required?: boolean;
}

const TextInputWrapper = ({ Icon, label, helperTip, required, ...props }: PropsIface) => {
  return (
    <div className='flex flex-col w-full gap-2'>
      {label && (
        <label htmlFor={props.id} className='block text-sm ml-0 font-medium text-slate-500 font-body'>
          {label}
          <span className='text-blue-500'>{required && '*'}</span>
        </label>
      )}
      <div className='flex items-center border rounded-full overflow-hidden text-white'>
        {Icon != null && <Icon className='text-slate-500 w-5 h-5 ml-3 mr-2' />}
        <input
          {...props}
          className={`${!Icon && 'px-4'} flex-1 p-2 font-medium text-slate-800 placeholder-slate-500 outline-none`}
          style={{ border: 'none' }}
        />
      </div>
      {helperTip && (
        <div className='flex flex-row items-center gap-1.5 text-slate-500'>
          <SvgInfo className='w-3 h-3' />
          <p className='text-xs'>{helperTip}</p>
        </div>
      )}
    </div>
  );
};

export default TextInputWrapper;
