import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../atoms/Button';

const MyShowcasesNullState = () => {
  return (
    <div className='hidden only:flex flex-col items-center gap-5 mt-12'>
      <img
        src={require('../../assets/img/morphis/morphis-new-message-notification.png')}
        className={'w-44 h-44 object-contain'}
        key={'zerostate'}
      />
      <div className='text-center flex flex-col gap-3'>
        <p className='text-xl font-semibold'>You haven't participated in any showcases yet.</p>
        <p className='text-slate-500 text-center w-2/3 text-balance mx-auto'>
          Complete showcases to bolster your profile and show companies what you bring to the table. Once you add a
          submission it will appear here!
        </p>
      </div>
      <Link to={'/discover'}>
        <Button>Find More Showcases</Button>
      </Link>
    </div>
  );
};

export default MyShowcasesNullState;
