import React, {useState} from 'react';
import NewUserDashboard from '../components/zerostates/NewUserDashboard';
import HomeMyShowcases from '../components/sections/HomeMyShowcases';
import SuccessModal from '../components/modals/SuccessModal';
import successful_submission from '../assets/img/morphis/morphis-team-work-and-joint-effort 1.png';
import successful_project from '../assets/img/morphis/morphis-checkmark-icon-with-frosted-glass-effect 1.png';
import {Showcase} from "../client/supabase/types";
import {getUserShowcases} from "../client/apiClient/users";
import moment from "moment/moment";
import Spinner from "../components/atoms/Spinner";

const HomePage = () => {
    const [allShowcases, setAllShowcases] = React.useState<Showcase[]>([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        getUserShowcases().then((showcases) => {
            setAllShowcases(showcases);
            setLoading(false);
        });
        window.gtag("event", "page_view", {
          "page_title": "Home Page"
        })
    }, []);

    // const upcomingShowcases = React.useMemo(() => {
    //     const nowMoment = moment();
    //     return allShowcases.filter((showcase) => moment(showcase.end_time) > nowMoment);
    // }, [allShowcases]);
    //
    // const completedShowcases = React.useMemo(() => {
    //     const nowMoment = moment();
    //     return allShowcases.filter((showcase) => moment(showcase.end_time) <= nowMoment);
    // }, [allShowcases]);


    return (
        <div className='w-full px-4'>
            {loading ?
                <div className='mx-auto w-full flex flex-col items-center text-blue-500 my-12'>
                    <Spinner size={5}/>
                </div>
                :
                allShowcases.length > 0 ? <HomeMyShowcases allShowcases={allShowcases} loading={loading}/> :
                    <NewUserDashboard/>}
            <SuccessModal
                isOpen={false}
                toggle={() => null}
                image={successful_submission}
                title='You’re all set!'
                description='Thanks for your submission! You should receive confirmation via email. Stay tuned for results to come.'
            />
            <SuccessModal
                isOpen={false}
                toggle={() => null}
                image={successful_project}
                title='Project added!'
                description='Thanks for linking your project! Your profile is looking stronger than ever.'
            />
        </div>
    );
};

export default HomePage;
