import React from 'react';
import OnboardingStepper from '../components/onboarding/OnboardingStepper';
import { useEffect, useState } from 'react';
import OnboardingName from '../components/onboarding/OnboardingName';
import OnboardingCity from '../components/onboarding/OnboardingCity';
import OnboardingSkillsInterests from '../components/onboarding/OnboardingSkillsInterests';
import OnboardingSocialLinks from '../components/onboarding/OnboardingSocialLinks';
import { MeshGradientRenderer } from '@johnn-e/react-mesh-gradient';
import HorizontalStepper from "../components/atoms/HorizontalStepper";
import SvgSearch from "../components/icons/SvgSearch";
import SvgLocation2 from "../components/icons/SvgLocation2";
import SvgProfile from "../components/icons/SvgProfile";
import SvgBriefcase from "../components/icons/SvgBriefcase";
import SvgStar from "../components/icons/SvgStar";
import SvgAttach from "../components/icons/SvgAttach";

const OnboardingPage = () => {
  const [step, setStep] = useState<number>(1);
  const [switchingStep, setSwitchingStep] = useState<boolean>(false);
  const [renderedStep, setRenderedStep] = useState<number>(1);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  // const [oneLiner, setOneLiner] = useState('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [profilePic, setProfilePic] = useState<string>('');
  const NUM_STEPS = 4;

  useEffect(() => {
    window.gtag("event", "page_view", {
      "page_title": "Onboarding"
    })
  }, [])

  useEffect(() => {
    if (!switchingStep) {
      setRenderedStep(step);
      window.gtag("event", "onboarding_step", {
        "step": step
      })
    }
  }, [switchingStep, step]);

  const changeStep = React.useCallback((newStep: number) => {
    setSwitchingStep(true);
    setTimeout(() => {
      setStep(newStep);
      setSwitchingStep(false);
    }, 300);
  }, []);

  const submit = React.useCallback(async () => {
    // TODO (dbhathena): submit to backend
  }, []);

  const goNextStep = React.useCallback(() => {
    if (step === NUM_STEPS) {
      submit();
      window.gtag("event", "onboarding_complete")
    }
    changeStep(step + 1);
  }, [step, changeStep, submit]);

  const goBackStep = React.useCallback(() => {
    changeStep(step - 1);
  }, [changeStep, step]);

  const stepStyle = React.useMemo(() => {
    return {
      opacity: switchingStep ? 0 : 1,
      transform: switchingStep ? 'translateY(20px)' : 'translateY(0)',
      transition: 'opacity 300ms, transform 300ms',
    };
  }, [switchingStep]);

  const RenderedStep = React.useMemo(() => {
    switch (renderedStep) {
      case 1:
        return <OnboardingName goNextStep={goNextStep} />;
      case 2:
        return <OnboardingCity goNextStep={goNextStep} goBackStep={goBackStep} />;
      case 3:
        return <OnboardingSkillsInterests goNextStep={goNextStep} goBackStep={goBackStep} />;
      case 4:
        return <OnboardingSocialLinks goNextStep={goNextStep} goBackStep={goBackStep} />;
      default:
        return null;
    }
  }, [renderedStep, profilePic, firstName, lastName, goNextStep, city, state, goBackStep]);

  const steps = [
    { label: 'About You', Icon: SvgProfile },
    { label: 'Your Work', Icon: SvgBriefcase },
    { label: 'Your Skills', Icon: SvgStar },
    { label: 'Your Links', Icon: SvgAttach },
  ];

  return (
    <div className='flex font-satoshi flex-col items-center min-h-[100vh] p-5 gap-5 relative'>
      <MeshGradientRenderer
        className='z-[100] opacity-20 absolute top-0 left-0 right-0 bottom-0'
        colors={['#ea66c3', '#6EC3F4', '#ff7575', '#d5ae13', '#6095fe']}
      />
      <HorizontalStepper steps={steps} currentStep={step} />
      <div className='flex flex-col items-center justify-center flex-1' style={stepStyle}>
        <div className='bg-white border border-slate-200 shadow-2xl p-5 md:p-12 rounded-2xl'>{RenderedStep}</div>
      </div>
    </div>
  );
};

export default OnboardingPage;
