import React, { SVGProps } from 'react';
import SvgClose from '../icons/SvgClose';

interface PropsIface {
  Icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  removable?: boolean;
  onClick?: () => void;
}

const TagComponent = ({ Icon, label, removable = false, onClick }: PropsIface) => {
  return (
    <div
      onClick={() => (!!onClick ? onClick() : null)}
      className={`bg-slate-200 whitespace-nowrap text-slate-700 px-2 md:px-3 py-0.5 md:py-1 rounded-full text-xs md:text-sm group ${removable && 'cursor-pointer hover:bg-red-100'}`}
    >
      {Icon && <Icon className={'inline-flex w-4 h-4 mb-1 mr-1'} />}
      {label}
      {removable && <SvgClose className={'inline-flex w-3 h-3 mb-1 ml-1 group-hover:text-red-400'} />}
    </div>
  );
};

export default TagComponent;
