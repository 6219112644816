import React, { useState } from 'react';
import ShowcaseCard from '../components/cards/ShowcaseCard';
import { getAllShowcases } from '../client/apiClient/showcases';
import moment from 'moment/moment';
import { Showcase } from '../client/supabase/types';
import Spinner from '../components/atoms/Spinner';
import MyShowcasesNullState from '../components/zerostates/MyShowcasesNullState';
import FutureShowcasesNullState from '../components/zerostates/FutureShowcasesNullState';

// type SkillType = {
//   fullStack: boolean;
//   backEnd: boolean;
//   frontEnd: boolean;
// };
//
// type Framework = {
//   react: boolean;
//   python: boolean;
//   flask: boolean;
// };

// type Filter = {
//   skillType: SkillType;
//   frameworksLanguages: Framework;
// };

const DiscoverPage = () => {
  // const [search, setSearch] = React.useState<string>('');
  // const [filters, setFilters] = React.useState<Filter>({
  //   skillType: {
  //     fullStack: false,
  //     backEnd: false,
  //     frontEnd: false
  //   },
  //   frameworksLanguages: {
  //     react: false,
  //     python: false,
  //     flask: false
  //   }
  // });
  const [showcases, setShowcases] = React.useState<Showcase[]>([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    getAllShowcases().then((res) => {
      setShowcases(res);
      setLoading(false);
    });
    window.gtag("event", "page_view", {
      'page_title': 'Discover'
    })
  }, []);

  const futureShowcases = React.useMemo<Showcase[]>(() => {
    const nowMoment = moment();
    showcases.sort(function (first, second) {
      return new Date(first.end_time).getTime() - new Date(second.end_time).getTime();
    })
    return showcases.filter((showcase) => moment(showcase.start_time) > nowMoment);
  }, [showcases]);

  // const handleCheckboxChange = React.useCallback((filterKey: keyof Filter, name: string, checked: boolean) => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     [filterKey]: {
  //       ...prev[filterKey],
  //       [name]: checked
  //     }
  //   }));
  // }, []);

  // const typeCheckboxes = React.useMemo(() => {
  //   return [
  //     { name: 'fullStack', label: 'Full Stack', checked: filters.skillType.fullStack },
  //     { name: 'backEnd', label: 'Back End', checked: filters.skillType.backEnd },
  //     { name: 'frontEnd', label: 'Front End', checked: filters.skillType.frontEnd }
  //   ];
  // }, [filters.skillType]);
  //
  // const frameworksLanguagesCheckboxes = React.useMemo(() => {
  //   return [
  //     { name: 'react', label: 'React', checked: filters.frameworksLanguages.react },
  //     { name: 'python', label: 'Python', checked: filters.frameworksLanguages.python },
  //     { name: 'flask', label: 'Flask', checked: filters.frameworksLanguages.flask }
  //   ];
  // }, [filters.frameworksLanguages]);

  return (
      <div className='flex flex-col gap-5 px-4'>
        <p className='text-2xl font-medium'>Open Showcases</p>
        {/*TODO: comment back in when filtering implemented*/}
        {/*<p className='text-2xl font-medium'>Discover Showcases</p>*/}
        {/*<TextInputWrapper*/}
        {/*  id='email'*/}
        {/*  type='email'*/}
        {/*  placeholder='Search showcases...'*/}
        {/*  Icon={SvgSearch}*/}
        {/*  value={search}*/}
        {/*  onChange={(e) => setSearch(e.target.value)}*/}
        {/*/>*/}
        {/*<div className='flex flex-row gap-3 text-slate-500 items-center justify-between'>*/}
        {/*  <div className='flex flex-row items-center gap-1'>*/}
        {/*    <p>Starting from:</p>*/}
        {/*    <Button size={'sm'} variant={'ghost'} Icon={SvgCaretDown}>*/}
        {/*      Today*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*  <Button size={'sm'} variant={'ghost'} Icon={SvgChevronRight}>*/}
        {/*    View Past Showcases*/}
        {/*  </Button>*/}
        {/*</div>*/}
        <div className='flex flex-row gap-5'>
          {/*TODO: comment back in when filtering implemented*/}
          {/*<div className='w-1/3 flex flex-col gap-8'>*/}
          {/*  <CheckboxGroup*/}
          {/*    label='Type'*/}
          {/*    items={typeCheckboxes}*/}
          {/*    onChange={(name, checked) => handleCheckboxChange('skillType', name, checked)}*/}
          {/*  />*/}
          {/*  <CheckboxGroup*/}
          {/*    label='Frameworks/Languages'*/}
          {/*    items={frameworksLanguagesCheckboxes}*/}
          {/*    onChange={(name, checked) => handleCheckboxChange('frameworksLanguages', name, checked)}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className='w-full flex flex-col gap-3'>
            {loading && (
                <div className='mx-auto text-blue-500 my-12'>
                  <Spinner size={5}/>
                </div>
            )}
            <FutureShowcasesNullState/>
            {futureShowcases.map((showcase, idx) => (
                <ShowcaseCard key={showcase.id} showcase={showcase} index={idx}/>
            ))}
          </div>
        </div>
      </div>
  );
};

export default DiscoverPage;
