import React, { useCallback, useState } from 'react';
import CheckboxGroup from '../../atoms/CheckboxGroup';
import TagsInput from '../../atoms/TagsInput';
import { fw_lang_tags, top_fw_lang_tags } from '../../../consts/tags';
import TextInputWrapper from '../../atoms/TextInputWrapper';
import { Skill } from '../../../client/supabase/types';
import { getAllSkills } from '../../../client/apiClient/skills';

interface PropsIface {
  selectedSkills: Skill[];
  setSelectedSkills: React.Dispatch<React.SetStateAction<Skill[]>>;
  school: string;
  setSchool: React.Dispatch<React.SetStateAction<string>>;
  gradYear: string;
  setGradYear: React.Dispatch<React.SetStateAction<string>>;
  occupation: string;
  setOccupation: React.Dispatch<React.SetStateAction<string>>;
}

const EditProfileOverview = ({
  selectedSkills,
  setSelectedSkills,
  school,
  setSchool,
  gradYear,
  setGradYear,
  occupation,
  setOccupation,
}: PropsIface) => {
  const [allSkills, setAllSkills] = useState<Skill[]>([]);
  const [popularSkills, setPopularSkills] = useState<Skill[]>([]);

  React.useEffect(() => {
    getAllSkills().then(({ allSkills, popularSkills }) => {
      setAllSkills(allSkills);
      setPopularSkills(popularSkills);
    });
  }, []);

  const labelFetcher = React.useCallback((skill: Skill) => {
    return skill.skill_type;
  }, []);

  const onSchoolChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setSchool(event.target.value);
    },
    [setSchool],
  );

  const onGradYearChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setGradYear(event.target.value);
    },
    [setGradYear],
  );

  const onOccupationChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setOccupation(event.target.value);
    },
    [setOccupation],
  );

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col md:flex-row gap-3'>
        <div className={'flex-[1]'}>
          <TextInputWrapper
            label={'Your School'}
            value={school}
            placeholder={'College University'}
            onChange={onSchoolChange}
          />
        </div>
        <div className={'flex-[.2]'}>
          <TextInputWrapper
            label={'Grad year'}
            value={gradYear}
            placeholder={'ie) 2020'}
            type={'number'}
            onChange={onGradYearChange}
          />
        </div>
      </div>
      <TextInputWrapper
        label={'Current (or last) position'}
        value={occupation}
        placeholder={'Software Engineer at Snapchat'}
        onChange={onOccupationChange}
      />
      <TagsInput<Skill>
        tags={selectedSkills}
        setTags={setSelectedSkills}
        placeholder={'None added'}
        options={allSkills}
        topOptions={popularSkills}
        label={'Frameworks and languages'}
        labelFetcher={labelFetcher}
      />
    </div>
  );
};

export default EditProfileOverview;
