import { ShowcaseQuery, PublicShowcaseQuery } from '../supabase/queries';
import { useAuthUser } from '../../state/authUser';
import { QueryData } from '@supabase/supabase-js';

export const getAllShowcases = async () => {
  const query = ShowcaseQuery();
  type QueryType = QueryData<typeof query>;
  const { data, error } = await query;
  if (error) throw error;
  return data as QueryType;
};

export const getShowcase = async (showcaseId: string) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }

  const { data, error } = await ShowcaseQuery().eq('id', showcaseId).eq('submissions.user_id', userId).single();
  if (error) throw error;
  return data;
};

export const getShowcasePublic = async (showcaseId: string) => {
  const { data, error } = await PublicShowcaseQuery().eq('id', showcaseId).single();
  if (error) throw error;
  return data;
};
