import { supabase } from '../supabase';
import { useAuthUser } from '../../state/authUser';
import { UserQuery } from '../supabase/queries';
import { QueryData } from '@supabase/supabase-js';
import { OfficePreferenceType, Showcase } from '../supabase/types';

export const getUser = async (queriedUserId:string|null=null) => {
  let userId = useAuthUser.getState().userId;
  if (!!queriedUserId) {
    userId = queriedUserId;
  }

  if (userId == null) {
    return;
  }

  const query = UserQuery().eq('id', userId).single();
  type QueryType = QueryData<typeof query>;
  const { data, error } = await query;
  if (error) throw error;
  return data as QueryType;
};

export const updateUser = async (
  firstName: string,
  lastName: string,
  headline: string,
  bio: string,
  socials: Record<string, string>,
  school: string,
  gradYear: string,
  occupation: string,
  skillsIds: number[],
  city: string,
  state: string,
  remotePreferences: string[],
  locationPreferences: string[],
  profilePic?: File,
) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }

  if (profilePic != null) {
    const { error: storageError } = await supabase.storage
      .from('profile_pictures')
      .upload(userId, profilePic, { upsert: true });
    if (storageError) {
      throw storageError;
    }
  }

  const userUpdatePromise = supabase
    .from('users')
    .update({
      first_name: firstName,
      last_name: lastName,
      headline,
      bio,
      social_links: socials,
      school,
      graduation_year: gradYear,
      occupation,
      city,
      state,
    })
    .eq('id', userId)
    .single();

  const skillsPromise = supabase
    .from('user_skills')
    .delete()
    .eq('user_id', userId)
    .then(async () => {
      await supabase.from('user_skills').insert(skillsIds.map((skillId) => ({ skill_id: skillId, user_id: userId })));
    });

  const preferencesPromise = supabase
    .from('office_preferences')
    .delete()
    .eq('user_id', userId)
    .then(async () => {
      await supabase.from('office_preferences').insert([
        ...remotePreferences.map((preference) => ({
          user_id: userId,
          preference,
          preference_type: 'REMOTE_STATUS' as OfficePreferenceType,
        })),
        ...locationPreferences.map((preference) => ({
          user_id: userId,
          preference,
          preference_type: 'LOCATION' as OfficePreferenceType,
        })),
      ]);
    });

  await Promise.all([userUpdatePromise, skillsPromise, preferencesPromise]);
};

export const getUserShowcases: () => Promise<Showcase[]> = async (queriedUserId:string|null = null) => {
  let userId = useAuthUser.getState().userId;
  if (!!queriedUserId) {
    userId = queriedUserId
  }
  if (userId == null) {
    return [];
  }
  const { data, error } = await supabase
    .from('users')
    .select(
      `
        submissions(
          *,
          showcases(
            *, 
            job_openings(*), 
            submissions(*),
            skills(skill_type)
          )
        )
      `,
    )
    .eq('id', userId)
    .not('submissions.showcase_id', 'is', null)
    .single();
  if (error) throw error;
  return data.submissions.map((s) => s.showcases).filter((s) => s != null) as Showcase[];
};

export const getUserOfficePreferences = async (queriedUserId:string|null = null) => {
  let userId = useAuthUser.getState().userId;
  if (!!queriedUserId) {
    userId = queriedUserId;
  }
  if (userId == null) {
    return;
  }
  const { data, error } = await supabase.from('office_preferences').select().eq('user_id', userId);
  if (error) throw error;
  return data;
};
