import React from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingButtons from './OnboardingButtons';
import CitySearchDropdown from '../atoms/CitySearchDropdown';
import CheckboxGroup from '../atoms/CheckboxGroup';
import TagsInput from '../atoms/TagsInput';
import { topCities } from '../../consts/tags';
import TextInputWrapper from '../atoms/TextInputWrapper';
import {
  IdentifiedCity,
  OfficePreferenceType,
  RemotePreference,
  RemotePreferenceFilter,
} from '../../client/supabase/types';
import { useAuthUser } from '../../state/authUser';
import { supabase } from '../../client/supabase';

const getOnboardingCityData = async () => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  const userPromise = supabase
    .from('users')
    .select(
      `
      occupation,
      city,
      state
    `,
    )
    .eq('id', userId)
    .single();
  const officePrefsPromise = supabase.from('office_preferences').select(`*`).eq('user_id', userId);
  const [userResponse, prefsResponse] = await Promise.all([userPromise, officePrefsPromise]);
  if (userResponse.error) throw userResponse.error;
  if (prefsResponse.error) throw prefsResponse.error;
  return { user: userResponse.data, officePreferences: prefsResponse.data };
};

const setOnboardingCityData = async (
  position: string,
  city: string,
  state: string,
  remotePreferences: string[],
  locationPreferences: string[],
) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  const userUpdatePromise = supabase
    .from('users')
    .update({ occupation: position, city, state })
    .eq('id', userId)
    .single();

  const preferenceInsertionPromise = supabase.from('office_preferences').insert([
    ...remotePreferences.map((preference) => ({
      user_id: userId,
      preference,
      preference_type: 'REMOTE_STATUS' as OfficePreferenceType,
    })),
    ...locationPreferences.map((preference) => ({
      user_id: userId,
      preference,
      preference_type: 'LOCATION' as OfficePreferenceType,
    })),
  ]);
  const preferenceDeletePromise = supabase
    .from('office_preferences')
    .delete()
    .eq('user_id', userId)
    .then(async () => await preferenceInsertionPromise);

  await Promise.all([userUpdatePromise, preferenceDeletePromise]);
};

interface PropsIface {
  goNextStep: () => void;
  goBackStep: () => void;
}

const OnboardingCity = ({ goNextStep, goBackStep }: PropsIface) => {
  const [filters, setFilters] = React.useState<RemotePreferenceFilter>({
    preference: {
      fullyRemote: false,
      hybrid: false,
      onSite: false,
    },
  });
  const [position, setPosition] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');
  const [state, setState] = React.useState<string>('');
  const [cities, setCities] = React.useState<IdentifiedCity[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const handlePositionChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setPosition(event.target.value);
  }, []);

  React.useEffect(() => {
    getOnboardingCityData().then((res) => {
      setPosition(res?.user.occupation ?? '');
      setCity(res?.user.city ?? '');
      setState(res?.user.state ?? '');
      const preferences: RemotePreference = {
        fullyRemote: false,
        hybrid: false,
        onSite: false,
      };
      res?.officePreferences
        .filter((pref) => pref.preference_type == 'REMOTE_STATUS')
        .forEach((pref) => {
          if (pref.preference in preferences) {
            preferences[pref.preference as keyof RemotePreference] = true;
          }
        });
      setFilters({ preference: preferences });
      const cities =
        res?.officePreferences.filter((pref) => pref.preference_type == 'LOCATION').map((pref) => pref.preference) ??
        [];
      setCities(cities.map((city) => ({ id: city, city })));
    });
  }, []);

  const handleCheckboxChange = React.useCallback((name: string, checked: boolean) => {
    setFilters((prev) => {
      return {
        ...prev,
        preference: {
          ...prev.preference,
          [name]: checked,
        },
      };
    });
  }, []);

  const typeCheckboxes = React.useMemo(() => {
    return [
      { name: 'fullyRemote', label: 'Fully remote', checked: filters.preference.fullyRemote },
      { name: 'hybrid', label: 'Hybrid', checked: filters.preference.hybrid },
      { name: 'onSite', label: 'On site', checked: filters.preference.onSite },
    ];
  }, [filters]);

  const disabled = React.useMemo(() => {
    if (!Object.values(filters.preference).some((p) => p)) {
      return true;
    }
    return !city || !state || !position;
  }, [city, state, position, filters]);

  const labelFetcher = React.useCallback((city: IdentifiedCity) => {
    return city.city;
  }, []);

  const onSubmit = React.useCallback(async () => {
    setLoading(true);
    const remotePreferences = Object.entries(filters.preference)
      .filter(([_, selected]) => selected)
      .map((p) => p[0]);
    await setOnboardingCityData(position, city, state, remotePreferences, cities.map(labelFetcher));
    setLoading(false);
    goNextStep();
  }, [position, city, state, filters.preference, cities, goNextStep, labelFetcher]);

  return (
      <div className='flex flex-col gap-8'>
        <OnboardingStepHeader
            title={'Where and how do you work?'}
            description={
              "Let us know where you live and where you'd like to work. This will help companies get a better idea of your needs, and make finding the right job that much easier."
            }
        />
        <TextInputWrapper
            label={'Current (or last) position'}
            value={position}
            placeholder={'Software Engineer at Snapchat'}
            onChange={handlePositionChange}
            helperTip={"Add your school, or independent work if this doesn't apply."}
        />
        <CitySearchDropdown label={'Your current city'} city={city} state={state} setCity={setCity}
                            setState={setState}/>
        <CheckboxGroup
            label='What is your remote preference?'
            labelSize={'sm'}
            direction={'row'}
            items={typeCheckboxes}
            onChange={handleCheckboxChange}
        />
        <div className='max-w-[500px]'>
          <TagsInput<IdentifiedCity>
              tags={cities}
              setTags={setCities}
              placeholder={'None added'}
              options={topCities}
              label={'Where would you like to work?'}
              labelFetcher={labelFetcher}
          />
        </div>
        <OnboardingButtons goNextStep={onSubmit} goBackStep={goBackStep} nextDisabled={disabled} loading={loading}/>
      </div>
  );
};

export default OnboardingCity;
