import React from 'react';

interface PropsIface {
  isOpen: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const CollapseComponent = ({ isOpen, style, className = '', children }: React.PropsWithChildren<PropsIface>) => {
  // Ref for the content div
  const contentRef = React.useRef<HTMLDivElement>(null);
  // State for the height of the content
  const [contentHeight, setContentHeight] = React.useState<React.CSSProperties['height']>(isOpen ? 'auto' : '0');

  React.useLayoutEffect(() => {
    // Function to update height
    const updateHeight = () => {
      setContentHeight(isOpen ? `${contentRef.current?.scrollHeight}px` : '0');
    };

    updateHeight();

    // If isOpen changes, update the height
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [isOpen]);

  return (
    <div
      style={{ ...style, height: contentHeight }}
      className={`transition-height duration-300 overflow-hidden ease-in-out ${className}`}
    >
      {/* Ref to the div so we can measure it */}
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

export default CollapseComponent;
