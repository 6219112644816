import React, {useState} from 'react';
import {getUserShowcases} from '../../client/apiClient/users';
import ShowcaseCard from '../cards/ShowcaseCard';
import moment from 'moment';
import {Showcase, User} from '../../client/supabase/types';
import MyShowcasesNullState from '../zerostates/MyShowcasesNullState';
import Spinner from '../atoms/Spinner';

type Tabs = 'Upcoming' | 'Completed';

interface PropsIface {
    allShowcases: Array<any>,
    loading: boolean
}

const HomeMyShowcases = ({allShowcases, loading}: PropsIface) => {
    // const [activeTab, setActiveTab] = React.useState<Tabs>('Upcoming');

    return (
        <div className='flex flex-col gap-5'>
            <p className='text-2xl font-medium'>My Completed Showcases</p>
            {/*<SectionTabs<Tabs> activeTab={activeTab} changeTab={setActiveTab} tabs={['Upcoming', 'Completed']} />*/}
            <div className='flex flex-col gap-3'>
                {loading && (
                    <div className='mx-auto text-blue-500 my-12'>
                        <Spinner size={5}/>
                    </div>
                )}
                <MyShowcasesNullState/>
                {allShowcases.map((showcase, idx) => (
                    <ShowcaseCard key={idx} showcase={showcase}/>
                ))}
            </div>
        </div>
    );
};

export default HomeMyShowcases;
