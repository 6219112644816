import React, { useRef, useState } from 'react';

interface PropsIface<T> {
  activeTab: T;
  changeTab: (tab: T) => void;
  tabs: T[];
}

const SectionTabs = <T extends string>({ activeTab, changeTab, tabs }: PropsIface<T>) => {
  const [indicatorOffset, setIndicatorOffset] = useState<number>(0);
  const [indicatorWidth, setIndicatorWidth] = useState<number>(0);
  const tabsRef = useRef<Record<T, React.RefObject<HTMLDivElement>>>({} as Record<T, React.RefObject<HTMLDivElement>>);

  React.useEffect(() => {
    const start: Record<T, React.RefObject<HTMLDivElement>> = {} as Record<T, React.RefObject<HTMLDivElement>>;
    tabs.forEach((tab) => {
      start[tab] = React.createRef<HTMLDivElement>();
    });
    tabsRef.current = start;
  }, [tabs]);

  const measureActiveTab = React.useCallback(() => {
    const currentTabDiv = tabsRef.current[activeTab]?.current;
    if (currentTabDiv) {
      setIndicatorOffset(currentTabDiv.offsetLeft);
      setIndicatorWidth(currentTabDiv.offsetWidth);
    }
  }, [activeTab]);

  React.useLayoutEffect(() => {
    measureActiveTab();
    // Adding a resize listener to adjust indicator on window resize
    window.addEventListener('resize', measureActiveTab);
    return () => window.removeEventListener('resize', measureActiveTab);
  }, [activeTab, tabs, measureActiveTab]); // Dependency on `tabs` ensures measurement when tabs array changes

  return (
    <div className='flex flex-row items-center' style={{ gap: '20px', position: 'relative' }}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          ref={tabsRef.current[tab]}
          onClick={() => changeTab(tab)}
          className={`pointer border-transparent pb-1 flex flex-col items-center border-b-2 ${activeTab === tab ? 'border-blue-400 text-blue-500' : 'text-slate-400 cursor-pointer hover:text-slate-600'} pb-2`}
        >
          <p className='text- md:text-lg font-semibold'>{tab}</p>
        </div>
      ))}
      <div
        className='bg-blue-500'
        style={{
          position: 'absolute',
          bottom: 0,
          left: indicatorOffset,
          width: indicatorWidth,
          height: '2px',
          transition: 'left 0.3s, width 0.3s',
        }}
      />
    </div>
  );
};

export default SectionTabs;
