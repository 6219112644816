import React from 'react';
import { Button } from '../atoms/Button';
import { cardClass } from '../../consts/styling';
import { IMAGES } from '../../assets/img';
import {Link} from "react-router-dom";
import SvgSearch from "../icons/SvgSearch";
import SvgProfile from "../icons/SvgProfile";

const FinishFillingOutProfileCard = () => {
  return (
    <div className={`${cardClass} flex-1 justify-between gap-3`}>
      <img alt={'arrow'} src={IMAGES.Brain} className='absolute z-[0] w-3/4 -right-12 opacity-30 blur-sm' />
      <div className='flex flex-col gap-3 z-[1]'>
        <p className='text-2xl font-medium'>
          Bolster up your profile
          <br /> your profile!
        </p>
        <p className='text-lg text-slate-500 mr-5'>
          Take care in crafting a solid profile, and add any notable projects you've worked on so that hiring managers can get the best
          picture of you!
        </p>
      </div>
        <Link to={'/profile'} className='ml-auto z-[1]' >
            <Button Icon={SvgProfile}>
                My Profile
            </Button>
        </Link>
    </div>
  );
};

export default FinishFillingOutProfileCard;
