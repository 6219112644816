import './App.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
