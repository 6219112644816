// DropzoneUploader.tsx
import React, { useCallback } from 'react';
import Dropzone, { Accept, useDropzone } from 'react-dropzone';
import SvgImage from './icons/SvgImage';

interface DropzoneUploaderProps {
  profileImageUrl?: string;
  onDropProfileImage: (files: File[]) => void;
}

const DropzoneUploader: React.FC<DropzoneUploaderProps> = ({ onDropProfileImage, profileImageUrl }) => {
  const acceptOptions: Accept = {
    'image/*': ['.jpeg', '.png'],
  };

  return (
    <Dropzone onDrop={onDropProfileImage} accept={acceptOptions} maxFiles={1} maxSize={5 * 1000000}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className='cursor-pointer'>
          <input {...getInputProps()} />
          <div style={{}} className=''>
            {profileImageUrl != null ? (
              <div className='w-28 h-28 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-full'>
                <img
                  className='w-28 h-28 object-cover bg-white aspect-square cursor-pointer  rounded-full'
                  src={profileImageUrl}
                  alt='profile-pic'
                />
              </div>
            ) : (
              <div className='w-28 h-28 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-full'>
                <SvgImage className={'text-blue-500'} />
                <p className='text-xs'>Add picture</p>
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default DropzoneUploader;
