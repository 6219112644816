import React from 'react';
import ShowcaseBody from '../components/sections/ShowcaseBody';
import { useParams } from 'react-router-dom';
import { getShowcasePublic } from '../client/apiClient/showcases';
import { PublicShowcase } from '../client/supabase/types';

type ShowcaseParams = {
  showcaseId: string;
};

const PublicShowcasePage = () => {
  const { showcaseId } = useParams<ShowcaseParams>();
  const [showcase, setShowcase] = React.useState<PublicShowcase | undefined>();

  React.useEffect(() => {
    if (showcaseId != null) {
      getShowcasePublic(showcaseId).then(setShowcase);
    }
  }, [showcaseId]);

  if (showcase == null) {
    // TODO (cory): add loading state
    return null;
  }

  return (
      <div className='flex flex-col gap-5 px-4 flex-1'>
        <div className='flex flex-row flex-1 text-sm md:text-2xl font-medium truncate'>
          <p className='opacity-50'>Showcases</p>
          <p className='font-medium truncate'>&nbsp;/ {showcase.title}</p>
        </div>
        <ShowcaseBody showcase={showcase} isPublic={true}/>
        {/*<ShowcaseSubmissionsSection submissions={showcase.submissions} />*/}
      </div>
  );
};

export default PublicShowcasePage;
