import React from 'react';
import { cardClass } from '../../consts/styling';
import { User } from '../../client/supabase/types';
import { supabase } from '../../client/supabase';
import SvgLocation from '../icons/SvgLocation';
import SvgSchool from '../icons/SvgSchool';
import SvgLocation2 from '../icons/SvgLocation2';
import SvgBriefcase from '../icons/SvgBriefcase';

interface PropsIface {
  user: User;
}

const ProfileCard = ({ user }: PropsIface) => {
  const profilePicUrl = React.useMemo(() => {
    return supabase.storage.from('profile_pictures').getPublicUrl(user.id).data.publicUrl;
  }, [user.id]);

  const numShowcases = React.useMemo(() => {
    return user.submissions.filter((s) => s.showcase_id != null).length;
  }, [user.submissions]);

  const numProjects = React.useMemo(() => {
    return user.submissions.filter((s) => s.showcase_id == null).length;
  }, [user.submissions]);

  return (
    <div className={`${cardClass} gap-3 flex-1`}>
      <div className='flex flex-col gap- items-center'>
        <img
          alt={'profile'}
          src={profilePicUrl ?? undefined}
          className='w-24 h-24 object-cover rounded-full bg-blue-500/15'
        />
        <p className='text-2xl font-semibold mt-2'>
          {user.first_name} {user.last_name}
        </p>
        <p className={`text-lg font-semibold text-slate-500 mb-3 ${!user.headline && 'opacity-50'}`}>
          {user.headline || 'No headline written'}
        </p>
        <div className='flex flex-col gap-1 text-sm items-center'>
          <div className='flex flex-row items-center gap-3'>
            <div className='flex flex-row items-center gap-1 text-slate-500'>
              <SvgBriefcase className='w-3 h-3' />
              <p>{user.occupation || 'No occupation'}</p>
            </div>
            <div className='flex flex-row items-center gap-1 text-slate-500'>
              <SvgSchool className='w-4 h-4' />
              <p>
                {user.school || 'No school'} {user.graduation_year}
              </p>
            </div>
          </div>
          {user.city && user.state && (
            <div className='flex flex-row items-center gap-1 text-slate-500'>
              <SvgLocation2 className='w-3 h-3' />
              <p>
                {user.city}, {user.state}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-row items-center justify-center gap-5'>
        <div className='text-slate-500 flex flex-col items-center'>
          <p>Showcases</p>
          <p className='text-xl text-slate-800 font-semibold'>{numShowcases}</p>
        </div>
        <div className='text-slate-500 flex flex-col items-center'>
          <p>Projects</p>
          <p className='text-xl text-slate-800 font-semibold'>{numProjects}</p>
        </div>
        {/* TODO (dbhathena): uncomment when implemented */}
        {/*<div className='text-slate-500 flex flex-col items-center'>*/}
        {/*  <p>Experience</p>*/}
        {/*  <p className='text-xl text-slate-800 font-semibold'>4yrs</p>*/}
        {/*</div>*/}
      </div>
      <div className='rounded-xl bg-blue-500/15 p-2.5 text-blue-500'>
        <p className={`font-medium text-center mr-5 ${!user.bio && 'opacity-50'}`}>{user.bio || 'No bio yet'}</p>
      </div>
    </div>
  );
};

export default ProfileCard;
