// DropzoneUploader.tsx
import React, { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

interface DropzoneUploaderProps {
  onImageUpload: (image: File) => void;
  hasCoverImage: boolean;
  label?: string;
}

const DropzoneUploader: React.FC<DropzoneUploaderProps> = ({ onImageUpload, label, hasCoverImage }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onImageUpload(acceptedFiles[0]); // Pass the image data back to the parent component
    },
    [onImageUpload],
  );

  const acceptOptions: Accept = {
    'image/*': ['.jpeg', '.png'],
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptOptions,
  });

  return (
    <div className='flex flex-col w-full gap-2'>
      {label && <label className='block text-sm ml-0 font-medium text-slate-500 font-body'>{label}</label>}
      <div
        {...getRootProps()}
        className='border-dashed text-sm text-slate-400 rounded-xl border-2 border-slate-300 p-4 text-center cursor-pointer'
      >
        <input {...getInputProps()} />
        {hasCoverImage ? (
          <p>Drop a different image to switch covers...</p>
        ) : isDragActive ? (
          <p>Drop the cover photo here ...</p>
        ) : (
          <p className=''>Drag 'n' drop a {label || 'cover photo'} here, or click to select one</p>
        )}
      </div>
    </div>
  );
};

export default DropzoneUploader;
