import React from 'react';
import TextInputWrapper from './TextInputWrapper';
import CollapseComponent from './CollapseComponent';
import { topUSCities } from '../../consts/tags';

type SearchResult = {
  id: string;
  city: string;
  state: string;
};

interface PropsIface {
  setCity: (city: string) => void;
  setState: (state: string) => void;
  city: string;
  state: string;
  label: string;
}

function CitySearchDropdown({ setCity, setState, city, state, label }: PropsIface) {
  const [search, setSearch] = React.useState<string>('');
  const [collapseOpen, setCollapseOpen] = React.useState<boolean>(false);
  const [searchResults, setSearchSearchResults] = React.useState<SearchResult[]>([]);

  const handleSearchChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value;
      setSearch(search);
      setCity('');
      setState('');
      const results = topUSCities.filter(({ city, state }) =>
        `${city}, ${state}`.toLowerCase().includes(search.toLowerCase()),
      );
      if (!search || results.length == 0) setCollapseOpen(false);
      else setCollapseOpen(true);
      setSearchSearchResults(results);
    },
    [setCity, setState],
  );

  React.useEffect(() => {
    if (!!city && !!state) {
      setSearch(`${city}, ${state}`);
    }
  }, [city, state]);

  const searchResultElements = React.useMemo(() => {
    return searchResults.map((res) => (
      <div
        key={res.id}
        onClick={() => {
          setCity(res.city);
          setState(res.state);
          setCollapseOpen(false);
        }}
        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'
      >
        <div className='flex flex-col'>
          <p className='text-md text-slate-800'>
            {res.city}, {res.state}
          </p>
        </div>
      </div>
    ));
  }, [searchResults]);

  return (
    <div className='flex flex-col relative w-full'>
      <TextInputWrapper
        label={label || 'Your city'}
        value={search}
        placeholder={'Search cities...'}
        onChange={handleSearchChange}
      />
      <div className='absolute top-16 left-0 right-0 z-[99]'>
        <CollapseComponent isOpen={search.length !== 0 && collapseOpen}>
          <div className='bg-white shadow-lg border border-neutral-200 rounded-lg max-h-[180px] overflow-y-scroll'>
            {searchResultElements}
          </div>
        </CollapseComponent>
      </div>
    </div>
  );
}

export default CitySearchDropdown;
