import React from 'react';
import {Tables} from '../../client/supabase/schema';

interface PropsIface {
    opening: Tables<'job_openings'>;
    id: number;
}

const ShowcaseJobOpeningItem = ({opening}: PropsIface) => {
    return (
        <div className='flex flex-col md:flex-row gap-3'>
            <div key={opening.id}
                 className='hidden md:flex bg-slate-100 flex-[.3] rounded-xl p-5 flex-row items-center gap-3'>
                <div className='flex flex-row items-center gap-3'>
                    <img key={opening.id} alt={'logo'} className='w-10 object-contain h-10' src={opening.logo_url}/>
                    <p className='text-lg font-medium'>{opening.company_name}</p>
                </div>
            </div>
            <div key={opening.id} className='bg-slate-100 rounded-xl flex-1 p-5 flex flex-row items-center gap-3'>
                <div className='flex flex-col md:flex-row  md:items-center gap-3 md:gap-8 justify-between flex-1'>
                    <div className='flex md:hidden flex-row items-center gap-3'>
                        <img key={opening.id} alt={'logo'} className='w-10 object-contain h-10' src={opening.logo_url}/>
                        <p className='text-lg font-medium'>{opening.company_name}</p>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='flex flex-col'>
                            <p className={'text-xs md:text-sm'}>Looking for</p>
                            <p className='font-medium'>{opening.looking_for}</p>
                        </div>
                        <div className='flex flex-col'>
                            <p className={'text-xs md:text-sm'}>Compensation</p>
                            <p className='font-medium'>{opening.compensation}</p>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <p className='text-lg text-green-500 font-semibold'>Role is still open!</p>
                        <a
                            href={opening.jd_url}
                            onClick={() => window.gtag("event", "click", {description: "Full description viewed", opening_id: opening.id})}
                            rel='noopener noreferrer'
                            target='_blank'
                            className='text-sm  cursor-pointer opacity-50 underline'
                        >
                            Full Job Description
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowcaseJobOpeningItem;
