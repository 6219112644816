import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      className='feather feather-linkedin'
      {...props}
    >
      <path d='M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6z' />
      <path d='M2 9H6V21H2z' />
      <circle cx={4} cy={4} r={2} />
    </svg>
  );
};

export default SvgComponent;
