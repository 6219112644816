import React, { useState } from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingButtons from './OnboardingButtons';
import TagsInput from '../atoms/TagsInput';
import TextInputWrapper from '../atoms/TextInputWrapper';
import { getAllSkills } from '../../client/apiClient/skills';
import { Skill } from '../../client/supabase/types';
import { useAuthUser } from '../../state/authUser';
import { supabase } from '../../client/supabase';

const getOnboardingSkillsData = async () => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }

  const userPromise = supabase
    .from('users')
    .select(
      `
      school,
      graduation_year
    `,
    )
    .eq('id', userId)
    .single();
  const skillsPromise = supabase
    .from('user_skills')
    .select(
      `
      skills(*)
    `,
    )
    .eq('user_id', userId);
  const [userRes, skillsRes] = await Promise.all([userPromise, skillsPromise]);

  if (userRes.error) throw userRes.error;
  if (skillsRes.error) throw skillsRes.error;

  return {
    user: userRes.data,
    skills: skillsRes.data.filter((skill) => !!skill).map((skill) => skill.skills) as Skill[],
  };
};

const setOnboardingSkillsData = async (school: string, graduationYear: string, skillsIds: number[]) => {
  const userId = useAuthUser.getState().userId;
  if (userId == null) {
    return;
  }
  const userPromise = supabase.from('users').update({ school, graduation_year: graduationYear }).eq('id', userId);
  const skillsInsertionPromise = supabase
    .from('user_skills')
    .insert(skillsIds.map((skillId) => ({ skill_id: skillId, user_id: userId })));
  const skillsDeletePromise = supabase
    .from('user_skills')
    .delete()
    .eq('user_id', userId)
    .then(async () => skillsInsertionPromise);
  await Promise.all([userPromise, skillsDeletePromise]);
};

interface PropsIface {
  goNextStep: () => void;
  goBackStep: () => void;
}

const OnboardingSkillsInterests = ({ goNextStep, goBackStep }: PropsIface) => {
  const [school, setSchool] = React.useState<string>('');
  const [gradYear, setGradYear] = React.useState<string>('');
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [allSkills, setAllSkills] = useState<Skill[]>([]);
  const [popularSkills, setPopularSkills] = useState<Skill[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    getAllSkills().then(({ allSkills, popularSkills }) => {
      setAllSkills(allSkills);
      setPopularSkills(popularSkills);
    });
    getOnboardingSkillsData().then((res) => {
      setSchool(res?.user.school ?? '');
      setGradYear(res?.user.graduation_year ?? '');
      setSelectedSkills(res?.skills ?? []);
    });
  }, []);

  const labelFetcher = React.useCallback((tag: Skill) => {
    return tag.skill_type;
  }, []);

  const handleSchoolChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setSchool(event.target.value);
  }, []);

  const handleGradYearChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setGradYear(event.target.value);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    if (!school || !gradYear || selectedSkills.length == 0) {
      return;
    }
    setLoading(true);
    await setOnboardingSkillsData(
      school,
      gradYear,
      selectedSkills.map((skill) => skill.id),
    );
    setLoading(false);
    goNextStep();
  }, [goNextStep, selectedSkills, school, gradYear]);

  const disabled = !school || !gradYear || selectedSkills.length == 0;

  return (
      <div className='flex flex-col gap-8'>
        <OnboardingStepHeader
            title={'A little more about you'}
            description={
              'Let us know your strengths as a developer. This helps companies get a better picture of your skills and experience, and helps us recommend more relevant showcases.'
            }
        />
        <div className='flex flex-col gap-5 w-full'>
          <div className='flex flex-col md:flex-row gap-3'>
            <div className={'flex-[1]'}>
              <TextInputWrapper
                  label={'Your School'}
                  value={school}
                  placeholder={'College University'}
                  onChange={handleSchoolChange}
              />
            </div>
            <div className={'flex-[.2]'}>
              <TextInputWrapper
                  label={'Grad year'}
                  value={gradYear}
                  placeholder={'ie) 2020'}
                  type={'number'}
                  onChange={handleGradYearChange}
              />
            </div>
          </div>
        </div>
       <div className='max-w-[500px]'>
         <TagsInput<Skill>
             tags={selectedSkills}
             setTags={setSelectedSkills}
             placeholder={'None added'}
             options={allSkills}
             topOptions={popularSkills}
             label={'Frameworks and languages'}
             labelFetcher={labelFetcher}
         />
       </div>
        <OnboardingButtons goNextStep={handleSubmit} goBackStep={goBackStep} nextDisabled={disabled} loading={loading}/>
      </div>
  );
};

export default OnboardingSkillsInterests;
