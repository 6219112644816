import React from 'react';
import CheckboxItem, { CheckboxType } from './CheckboxItem';
import { TextSize } from '../../utils/types';

interface PropsIface<T> {
  label: string;
  items: CheckboxType<T>[];
  onChange: (name: T, checked: boolean) => void;
  labelSize?: TextSize;
  direction?: 'row' | 'col';
  required?: boolean;
}

const CheckboxGroup = <T extends string>({
  label,
  items,
  onChange,
  required,
  labelSize = 'base',
  direction = 'col',
}: PropsIface<T>) => {
  return (
    <div className='flex flex-col gap-3'>
      <p className={`text-slate-500 font-medium text-sm text-${labelSize}`}>
        {label}
        <span className='text-blue-500'>{required && '*'}</span>
      </p>
      <div className={`flex ${direction === 'row' ? 'flex-row gap-5' : 'flex-col gap-3'}`}>
        {items.map((item) => (
          <CheckboxItem<T>
            key={item.name}
            name={item.name}
            label={item.label}
            checked={item.checked}
            onChange={(e) => onChange(item.name, e.target.checked)}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
