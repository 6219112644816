import React from 'react';
import SvgInfo from '../icons/SvgInfo';

interface PropsIface
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
  helperTip?: string;
  required?: boolean;
}

const TextAreaWrapper = ({ label, helperTip, onChange, required, ...props }: PropsIface) => {
  return (
    <div className='flex flex-col w-full gap-2'>
      {label && (
        <label htmlFor={props.id} className='block text-sm ml-0 font-medium text-slate-500 font-body'>
          {label}
          <span className='text-blue-500'>{required && '*'}</span>
        </label>
      )}
      <div className='flex items-center border rounded-3xl p-2 text-white'>
        <textarea
          {...props}
          onChange={onChange}
          className={`mx-2 flex-1 min-h-[50px] font-medium text-slate-800 placeholder-slate-500 outline-none`}
          style={{ border: 'none' }}
        />
      </div>
      {helperTip && (
        <div className='flex flex-row items-center gap-1 text-slate-500'>
          <SvgInfo className='w-4 h-4' />
          <p className='text-xs'>{helperTip}</p>
        </div>
      )}
    </div>
  );
};

export default TextAreaWrapper;
