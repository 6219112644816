import { supabase } from '../supabase';
import { useAuthUser } from '../../state/authUser';
import { Skill } from '../supabase/types';

export const getAllSkills = async () => {
  const { data, error } = await supabase.from('skills').select('*');
  if (error) throw error;
  return {
    allSkills: data,
    popularSkills: data?.filter((s) => s.is_popular),
  };
};

export const getUserSkills = async (queriedUserId:string|null = null) => {
  let userId = useAuthUser.getState().userId;
  if (!!queriedUserId) {
    userId = queriedUserId
  }
  if (userId == null) {
    return;
  }
  const { data, error } = await supabase
    .from('user_skills')
    .select(
      `
      skills(*)
    `,
    )
    .eq('user_id', userId);
  if (error) throw error;
  if (!data) return [];
  return data.map((s) => s.skills) as Skill[];
};
