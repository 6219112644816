import { Buffer } from 'buffer';
const BASE_URL: string = "https://api.mailgun.net";
const USERNAME: string = process.env.REACT_APP_MAILGUN_API_USERNAME || "";
const PASSWORD: string = process.env.REACT_APP_MAILGUN_API_PASSWORD || "";

export const sendEmail = async (
  to: string,
  subject: string,
  html: string,
) => {
  const domain = '10foldhire.com';
  const url = `${BASE_URL}/v3/${domain}/messages`;
  
  const form = new FormData();
  form.append("from", `10Fold <hello@${domain}>`);
  form.append("to", to);
  form.append("subject", subject);
  form.append("html", html);

  const res = await fetch(url, {
    method: "POST",
    headers: {
      'Authorization': 'Basic ' + Buffer.from(USERNAME + ":" + PASSWORD).toString("base64"),
    },
    body: form
  })
  const json = await res.json();
  return json;
}

