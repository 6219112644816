import React from 'react';
import {Button} from '../atoms/Button';
import SvgSearch from '../icons/SvgSearch';
import {cardClass} from '../../consts/styling';
import {IMAGES} from '../../assets/img';
import {Link} from "react-router-dom";

function RegisterForFirstShowcaseCard() {
    return (
        <div className={`${cardClass} flex-1 justify-between gap-3`}>
            <img alt={'arrow'} src={IMAGES.Arrow} className='absolute z-[0] w-3/4 -right-1 opacity-30 blur-sm'/>
            <div className='flex flex-col gap-3'>
                <p className='text-2xl font-medium'>
                    Register for your
                    <br/> first showcase!
                </p>
                <p className='text-lg text-slate-500 mr-5 z-[1]'>
                    Thanks for joining 10Fold! We’re glad to have you. Check out our list of showcases to find the one
                    that fits
                    you best.
                </p>
            </div>
            <Link to={'/discover'} className='ml-auto z-[1]' >
                <Button Icon={SvgSearch}>
                    Find Showcases
                </Button>
            </Link>
        </div>
    );
}

export default RegisterForFirstShowcaseCard;
