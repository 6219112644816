import { supabase } from './index';

export const UserQuery = () =>
  supabase.from('users').select(`
      *,
      social_links->X,
      social_links->LinkedIn,
      social_links->Github,
      office_preferences(*), 
      submissions(
        *,
        showcases(
          *, 
          job_openings(*), 
          submissions(*),
          skills(skill_type)
        )
      ), 
      skills(skill_type)
    `);

export const ShowcaseQuery = () =>
  supabase.from('showcases').select(`
      *,
      job_openings(*), 
      submissions(*),
      skills(skill_type)
    `);

export const PublicShowcaseQuery = () =>
    supabase.from('showcases').select(`
        *,
        job_openings(*), 
        skills(skill_type)
      `);
  
