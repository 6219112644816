import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={24}
      viewBox='0 -960 960 960'
      width={24}
      {...props}
      fill={'currentColor'}
    >
      <path d='M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80zM360-200q-17 0-28.5-11.5T320-240q0-17 11.5-28.5T360-280h240q17 0 28.5 11.5T640-240q0 17-11.5 28.5T600-200H360zm-30-120q-69-41-109.5-110T180-580q0-125 87.5-212.5T480-880q125 0 212.5 87.5T780-580q0 81-40.5 150T630-320H330zm24-80h252q45-32 69.5-79T700-580q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 54 24.5 101t69.5 79zm126 0z' />
    </svg>
  );
};

export default SvgComponent;
